/* eslint-disable */
import React, { useContext } from "react";
import { PopupContext } from "../contexts/Contexts";

export const usePopup = () => {
    const context = useContext(PopupContext);

    if (!context) {
        throw new Error("usePopup have to be used within PopupContext");
    }

    return context;
}
/* eslint-enable */