import ReactDOM from 'react-dom/client';
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import "./assets/css/app.css";
import {AuthProvider} from './app/providers/AuthProvider';
import Home from "./app/pages/Home";
import NotFoundPage from "./app/pages/NotFoundPage";
import {SignIn} from "./app/components/SignIn";
import {SignOut} from "./app/components/SignOut";
import ServiceUnavailable from "./app/pages/ServiceUnavailable";
import {Environment} from "./shared-api/helpers/Environment";
import {useInitializeSession} from "./app/hooks/Hooks";

export const Init = () => {
    useInitializeSession();
    
    const routes = [
        {path: 'home', element: <Home/>},
        {path: 'signin', element: <SignIn/>},
        {path: 'signout', element: <SignOut/>},
        {path: 'notfound', element: <NotFoundPage/>},
        {path: 'serviceunavailable', element: <ServiceUnavailable/>}
    ];

    return (
        <Routes>
            {/*<Route path="/" element={<Home/>}/>*/}
            {routes.map(({element, path}) => <Route key={path} path={path} element={element}/>)}
            <Route path="*" element={<Navigate to='/notfound'/>}/>
            <Route index element={<Navigate to='/signin'/>}/>
        </Routes>
    );
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <BrowserRouter basename={Environment.get('REACT_APP_CONTEXT')}>
        <AuthProvider>
            <Init/>
        </AuthProvider>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
