/**
 * Um símbolo globalmente único usado como chave para armazenar e acessar o token de acesso
 * dentro do objeto `internalToken`. O uso de `Symbol.for` garante que o mesmo símbolo será
 * recuperado em chamadas subsequentes com o mesmo argumento, permitindo o acesso consistente
 * ao valor do token em diferentes partes da aplicação.
 */
const tokenSymbol: symbol = Symbol.for('accessToken');

/**
 * Define um tipo para objetos que armazenam tokens de acesso. A chave é um `symbol`,
 * garantindo que o token seja armazenado de forma segura e evitando conflitos de propriedade.
 * O valor do token pode ser uma string (quando um token está presente) ou `undefined`
 * (quando nenhum token está armazenado).
 */
type TokenObject = {
    [key: symbol]: string | undefined;
};

/**
 * `internalToken` é uma instância de `Proxy` que encapsula um `TokenObject`.
 * Este proxy gerencia o acesso e a modificação do token de acesso, utilizando os métodos `get` e `set`
 * para intermediar operações sobre o objeto interno. O uso de `Proxy` e `Reflect` permite
 * uma manipulação mais sofisticada e controlada do token de acesso, como validações ou
 * transformações adicionais durante a leitura ou escrita do token.
 */
const internalToken = new Proxy<TokenObject>({ [tokenSymbol]: undefined }, {
    get(target: TokenObject, prop: symbol): string | undefined {
        return Reflect.get(target, prop);
    },
    set(target: TokenObject, prop: symbol, value: string | undefined): boolean {
        return Reflect.set(target, prop, value);
    }
});

/**
 * `TokenUtil` é um objeto utilitário que expõe métodos para manipular o token de acesso.
 * Oferece uma interface simplificada para obter e definir o valor do token de acesso,
 * escondendo os detalhes de implementação relacionados ao uso do `Proxy` e do `symbol`.
 */
const TokenUtil = {
    /**
     * Retorna o token de acesso atual. Se nenhum token estiver armazenado, retorna uma string vazia.
     * @returns O token de acesso como uma string, ou uma string vazia se nenhum token estiver presente.
     */
    getToken(): string {
        if (!internalToken[tokenSymbol]) {
            internalToken[tokenSymbol] = sessionStorage.getItem(tokenSymbol.toString()) ?? '';
        }
        return internalToken[tokenSymbol];
    },

    /**
     * Define o valor do token de acesso. Se o valor fornecido for uma string vazia ou apenas espaços,
     * o token é definido como `undefined`, efetivamente "apagando" o token armazenado.
     * @param value O novo valor para o token de acesso. Strings vazias ou com espaços são tratadas como ausência de token.
     */
    setToken(value: string) {
        internalToken[tokenSymbol] = value.trim() ? value : undefined;
        sessionStorage.setItem(tokenSymbol.toString(), internalToken[tokenSymbol] ?? '');
    },

    getDecodeObject(): React.ReactNode {
        return TokenDecode.jwtDecode(this.getToken());
    }
}

type DecodeOptions = {
    header?: boolean;
};


class InvalidTokenError extends Error {
    constructor(message: string) {
        super(message);
        this.name = "InvalidTokenError";
    }
}

class TokenDecode {
    public static jwtDecode(token: string, options: DecodeOptions = {}): any {
        if (typeof token !== "string") {
            throw new InvalidTokenError("Invalid token specified");
        }

        const parts = token.split(".");

        if (parts.length !== 3) {
            throw new InvalidTokenError("Invalid token specified");
        }

        const pos = options.header === true ? 0 : 1;
        try {
            return JSON.parse(this.base64UrlDecode(parts[pos]));
        } catch (error) {
            if (error instanceof Error) {
                throw new InvalidTokenError("Invalid token specified: " + error.message);
            }
        }
    }

    private static base64UrlDecode(str: string): string {
        let output = str.replace(/-/g, "+").replace(/_/g, "/");
        switch (output.length % 4) {
            case 0: break;
            case 2: output += "=="; break;
            case 3: output += "="; break;
            default: throw new InvalidTokenError("Illegal base64url string!");
        }

        try {
            return this.b64DecodeUnicode(output);
        } catch (error) {
            return window.atob(output);
        }
    }

    private static b64DecodeUnicode(str: string): string {
        return decodeURIComponent(
            window.atob(str).replace(/(.)/g, function (m, p) {
                const code = p.charCodeAt(0).toString(16).toUpperCase();
                return "%" + (code.length < 2 ? "0" + code : code);
            })
        );
    }
}

export { TokenUtil };
