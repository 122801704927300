import {IRequestConfig} from "../../shared-api/models/IRequestConfig";
import {ApiRequestHandler} from "../../shared-api/handlers/ApiRequestHandler";
import {QueryParamsBuilder} from "../../shared-api/helpers/URLBuilder";

class FormUrlEncodedConfig implements IRequestConfig {
    getHeaders(): HeadersInit {
        return {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
        };
    }
}

export class LoginService {

    static async post(input: RequestInfo | URL, init?: Record<string, string>) {
        // Convertendo os dados de init para URLSearchParams
        const formBody = QueryParamsBuilder.build(init);
        // Utilizando ApiRequestHandler com a configuração específica
        return await ApiRequestHandler.post(input, formBody, new FormUrlEncodedConfig());
    }
}
