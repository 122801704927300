import {CdaService} from "../services/CdaService";
import {IService} from "../interfaces/IService";
import {BaseController} from "./BaseController";
import {IColumnDefinition} from "../../app/types/Interfaces";
import {CurrencyUtil} from "../../app/utils/Utils";

export class CdaController extends BaseController {
    constructor(service?: IService) {
        super(service ?? new CdaService());
    }

    protected createColumnsDefinition(): IColumnDefinition[] {
        return [
            {key: "numeroCDA", header: "Número CDA"},
            {key: "documento", header: "CPF/CNPJ", path: "contribuinte.documento.numero"},
            {key: "contribuinte", header: "Contribuinte", path: "contribuinte.nome"},
            {key: "dataInscricaoCDA", header: "Data Inscrição CDA"},
            {key: "situacaoAtualProcesso", header: "Situação Atual Processo"},
            {key: "tipoProcesso", header: "Tipo de Processo"},
            {key: "valorTotal", header: "Valor Total", format: (data) => CurrencyUtil.format(data)},
        ];
    }
}