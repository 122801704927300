/* eslint-disable */
import React, { createContext } from "react";
import { IUser, TSession } from "../../mtlogin-api/controllers/MTLoginUserController";

export interface AuthContextType {
    session: TSession;
    login: (user: IUser, registered: boolean) => void;
    logout: () => void;
  }

// create context
const AuthContext = createContext<AuthContextType | undefined>(undefined);

export default AuthContext;
/* eslint-enable */