import React, {useCallback, useState} from 'react';
import {Button} from 'primereact/button';
import {useToast} from "../../hooks/useToast";

interface IExportGroupProps {
    label: string;
    tooltip: string;
    visible: boolean;
    onAction?: () => Promise<void> | void;
}

const ExportGroup = ({label, tooltip, visible, onAction}: IExportGroupProps) => {
    const [loading, setLoading] = useState(false);
    const {showInfo, showError, showSuccess} = useToast();

    const exportPdf = useCallback(async () => {
        setLoading(true);

        showInfo('Exportando para arquivo', `Por favor, aguarde!`);

        try {
            await onAction?.();
            showSuccess('Exportado para arquivo', `Arquivo exportado com sucesso.`);
        } catch (error) {
            if (error instanceof Error) {
                showError('Erro na exportação', error.message);
            }
        } finally {
            setLoading(false)
        }
        // eslint-disable-next-line
    },[]);


    return (
        visible ?
            <div className="flex align-items-center justify-content-end gap-2">
                <div>
                    {label}
                </div>
                <div>
                    <Button
                        type="button"
                        icon="pi pi-file-pdf"
                        loading={loading}
                        onClick={exportPdf}
                        data-pr-tooltip={tooltip}
                    />
                </div>
            </div> :
            <></>
    );
}

export default ExportGroup;