// CdaService.ts

import {Endpoints} from "../Endpoints";
import {BaseService} from "./BaseService";
import {IService} from "../interfaces/IService";

export class CdaService extends BaseService implements IService {
    constructor() {
        super(Endpoints.CDAS);
    }
}