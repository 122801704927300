import {TDocumentType} from "../types/types";
import {StringUtil} from "./Utils";

/**
 * Objeto utilitário para a validação de diferentes tipos de documentos brasileiros.
 * Provê métodos estáticos para validar CPF, CNPJ, a base do CNPJ e números de processos judiciais (CNJ),
 * utilizando uma fábrica para obter o validador específico de cada tipo de documento.
 */
export const ValidateUtil = {
    /**
     * Valida um número de processo judicial no formato CNJ.
     * Através da `ValidatorFactory`, obtém um `CNJValidator` e chama `isValid` para avaliar
     * se o número do processo está corretamente formatado e possui dígitos verificadores válidos.
     *
     * @param value O número do processo a ser validado. Espera-se um formato específico com 20 dígitos.
     * @returns Verdadeiro se o número do processo for válido, falso caso contrário.
     */
    cnj(value: string): boolean {
        return ValidatorFactory.getValidator('PROCESSO').isValid(value);
    },

    /**
     * Valida um número de CPF fornecido.
     * Utiliza a `ValidatorFactory` para obter uma instância de `CPFValidator` e então
     * invoca o método `isValid` para determinar se o CPF é válido.
     *
     * @param value O valor do CPF a ser validado. O valor pode estar formatado ou não.
     * @returns Verdadeiro se o CPF for válido, falso caso contrário.
     */
    cpf(value: string): boolean {
        return ValidatorFactory.getValidator('CPF').isValid(value);
    },

    /**
     * Valida um número de CNPJ fornecido.
     * Emprega a `ValidatorFactory` para adquirir uma instância de `CNPJValidator` e
     * utiliza seu método `isValid` para verificar a validade do CNPJ.
     *
     * @param value O valor do CNPJ a ser validado. Pode estar formatado com pontuação ou ser uma string numérica pura.
     * @returns Verdadeiro se o CNPJ for válido, falso caso contrário.
     */
    cnpj(value: string): boolean {
        return ValidatorFactory.getValidator('CNPJ').isValid(value);
    },

    /**
     * Valida a raiz de um CNPJ (os primeiros 8 dígitos de um CNPJ).
     * Utiliza a `ValidatorFactory` para obter uma instância de `CNPJBaseNumberValidator`,
     * que verifica se o valor fornecido corresponde a um formato válido de raiz de CNPJ,
     * consistindo exatamente de 8 dígitos.
     *
     * @param value A raiz do CNPJ a ser validada, que deve ser uma string de exatamente 8 dígitos.
     * @returns Verdadeiro se a raiz do CNPJ tiver o formato correto, falso caso contrário.
     */
    cnpjBase(value: string): boolean {
        return ValidatorFactory.getValidator('RADICAL_CNPJ').isValid(value);
    }
}

/**
 * Fábrica para criação de validadores de documentos.
 * Oferece um método estático para obter uma instância de validador específica
 * baseada no tipo de documento a ser validado.
 */
class ValidatorFactory {
    /**
     * Obtém uma instância do validador correspondente ao tipo de documento especificado.
     * Este método é um ponto central para a obtenção de validadores específicos,
     * permitindo a fácil expansão do sistema de validação para novos tipos de documentos.
     *
     * @param type O tipo de documento para o qual um validador é necessário. Deve ser um dos valores
     *             pré-definidos no tipo `TDocumentType`, como 'CPF', 'CNPJ', 'RADICAL_CNPJ', ou 'PROCESSO'.
     * @returns Uma instância de `BaseValidator` específica para o tipo de documento fornecido.
     * @throws Error Se o tipo de documento fornecido não for reconhecido, uma exceção será lançada
     *               indicando que o tipo de validador não é reconhecido.
     */
    static getValidator(type: TDocumentType): BaseValidator {
        switch (type) {
            case 'CPF':
                return new CPFValidator();
            case 'CNPJ':
                return new CNPJValidator();
            case 'RADICAL_CNPJ':
                return new CNPJBaseNumberValidator();
            case 'PROCESSO':
                return new CNJValidator();
            default:
                throw new Error('Validator type not recognized');
        }
    }
}

/**
 * Classe abstrata que serve de base para a validação de documentos.
 */
abstract class BaseValidator {
    /**
     * Método abstrato para calcular e comparar os dígitos verificadores do documento.
     * @param value Valor do documento a ser validado.
     * @returns Verdadeiro se os dígitos verificadores estiverem corretos, falso caso contrário.
     */
    protected abstract calculateAndCompareDigits(value: string): boolean;

    /**
     * Valida o documento verificando se não está vazio, se não consiste em uma sequência de dígitos iguais,
     * e se passa pela validação específica de dígitos verificadores.
     * @param value Valor do documento a ser validado.
     * @returns Verdadeiro se o documento for válido, falso caso contrário.
     */
    public isValid(value: string): boolean {
        if (typeof value !== 'string' || !value.trim()) {
            return false;
        }

        const unmaskedValue = StringUtil.extractDigits(value);
        if (!unmaskedValue || this.isEqualDigitSequence(unmaskedValue)) {
            return false;
        }

        return this.calculateAndCompareDigits(unmaskedValue);
    }

    /**
     * Verifica se uma string consiste em uma sequência de um único dígito repetido.
     * @param value Valor a ser verificado.
     * @returns Verdadeiro se a sequência consistir de um único dígito repetido, falso caso contrário.
     */
    private isEqualDigitSequence(value: string): boolean {
        // Regex que combina com uma string consistindo de um único dígito repetido
        // ^ - início da string
        // (\d) - captura um dígito e o armazena como um grupo de captura para referência futura
        // \1* - refere-se ao dígito capturado pelo primeiro grupo de captura (\d) e verifica se é repetido 0 ou mais vezes
        // $ - fim da string
        return /^(.)\1*$/.test(value);
    }

    /**
     * Calcula um checksum usando o módulo 97, método utilizado para validar alguns tipos de documentos.
     * @param value Valor base para o cálculo.
     * @param partSize Tamanho das partes em que o valor será dividido para o cálculo.
     * @returns O checksum calculado como uma string.
     */
    protected checksumForMod97(value: string, partSize: number): string {
        let partialResult = 0;

        // Itera sobre a base de cálculo em partes de tamanho definido por `partSize`.
        for (let i = 0; i < value.length; i += partSize) {
            // Extrai uma parte da base de cálculo.
            const part = value.substring(i, i + partSize);
            // Aplica a função mod97 ao resultado parcial concatenado com a parte atual.
            partialResult = (parseInt(partialResult + part) % 97);
        }

        // Calcula o checksum final a partir do último resultado parcial.
        return (98 - partialResult).toString().padStart(2, '0');
    }

    /**
     * Calcula um checksum utilizando o algoritmo do módulo 11.
     * Este método é amplamente utilizado na validação de documentos brasileiros, como CPF e CNPJ,
     * devido à sua eficácia em detectar erros comuns de digitação.
     *
     * @param value - A string representando o valor numérico base para o cálculo do checksum.
     *                Cada caractere desta string deve ser um dígito de 0 a 9.
     * @param weights - Um array de números representando os pesos a serem aplicados a cada dígito
     *                  do valor durante o cálculo do checksum. O comprimento deste array deve
     *                  corresponder ao comprimento da string `value`.
     * @returns Retorna o dígito verificador calculado como uma string. Se o resultado do cálculo
     *          for 10 ou mais, o dígito verificador retornado é '0', conforme a regra comum do módulo 11.
     */
    protected checksumForMod11(value: string, weights: number[]): string {
        let sum = 0; // Inicializa a soma dos produtos dos dígitos pelos pesos.

        // Itera sobre cada caractere da string `value`.
        for (let i = 0; i < value.length; i++) {
            // Calcula a soma dos produtos dos dígitos pelos seus respectivos pesos.
            sum += parseInt(value.charAt(i), 10) * weights[i];
        }

        // Calcula o checksum baseado no módulo 11.
        const checksum = 11 - (sum % 11);

        // Converte o checksum em string. Se o checksum for 10 ou maior, retorna '0'.
        return (checksum >= 10 ? 0 : checksum).toString();
    }
}

/**
 * Validador específico para o documento CPF (Cadastro de Pessoas Físicas).
 * Implementa a lógica de validação do CPF verificando o tamanho, a não repetição de dígitos
 * e o cálculo correto dos dígitos verificadores usando o algoritmo do módulo 11.
 */
class CPFValidator extends BaseValidator {
    protected calculateAndCompareDigits(cpf: string): boolean {
        // Verifica se o CPF possui o tamanho esperado de 11 dígitos. Caso não, retorna falso.
        if (cpf.length !== 11) {
            return false;
        }

        // Extrai os primeiros 9 dígitos do CPF, que serão usados no cálculo dos dígitos verificadores.
        const valueForCalculation = StringUtil.firstDigits(cpf, 9);

        // Define os pesos utilizados no cálculo do módulo 11, que são usados na geração dos dígitos verificadores.
        const weights = [11, 10, 9, 8, 7, 6, 5, 4, 3, 2];

        // Calcula o primeiro dígito verificador.
        const d1 = this.checksumForMod11(valueForCalculation, weights.slice(1));

        // Calcula o segundo dígito verificador, usando o primeiro dígito já calculado.
        const d2 = this.checksumForMod11(valueForCalculation + d1, weights.slice());

        // Compara os dois dígitos verificadores calculados com os últimos dois dígitos do CPF fornecido.
        return StringUtil.lastDigits(cpf, 2) === d1 + d2;
    }
}

/**
 * Validador específico para o documento CNPJ (Cadastro Nacional da Pessoa Jurídica).
 * Realiza a validação de um CNPJ, incluindo o tamanho esperado de 14 dígitos,
 * a verificação da não sequencialidade dos dígitos e a correta geração dos dígitos verificadores
 * conforme o algoritmo do módulo 11.
 */
class CNPJValidator extends BaseValidator {
    /**
     * Calcula e compara os dígitos verificadores do CNPJ fornecido com aqueles calculados.
     *
     * @param cnpj A string representando o CNPJ a ser validado. Espera-se que seja um número de 14 dígitos sem máscara.
     * @returns {boolean} Retorna true se o CNPJ for válido, ou false caso contrário.
     */
    protected calculateAndCompareDigits(cnpj: string): boolean {
        // Verifica se o CNPJ possui o tamanho esperado de 14 dígitos. Caso não, retorna falso.
        if (cnpj.length !== 14) {
            return false;
        }

        // Extrai os primeiros 12 dígitos do CNPJ, que serão usados no cálculo dos dígitos verificadores.
        const valueForCalculation = StringUtil.firstDigits(cnpj, 12);

        // Define os pesos utilizados no cálculo do módulo 11, que são usados na geração dos dígitos verificadores.
        const weights = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];

        // Calcula o primeiro dígito verificador.
        const d1 = this.checksumForMod11(valueForCalculation, weights.slice(1));

        // Calcula o segundo dígito verificador, usando o primeiro dígito já calculado.
        const d2 = this.checksumForMod11(valueForCalculation + d1, weights.slice());

        // Compara os dois dígitos verificadores calculados com os últimos dois dígitos do CNPJ fornecido.
        return StringUtil.lastDigits(cnpj, 2) === d1 + d2;
    }
}

/**
 * Validador para a raiz de um CNPJ (os primeiros 8 dígitos do CNPJ).
 * Esta validação é mais simples, focando apenas na verificação do comprimento da raiz,
 * que deve ser exatamente de 8 dígitos, indicando um formato válido para a raiz de um CNPJ.
 */
class CNPJBaseNumberValidator extends BaseValidator {
    /**
     * Valida a raiz de um CNPJ fornecido.
     *
     * @param raizCnpj - String representando a raiz do CNPJ a ser validada. Espera-se que contenha exatamente 8 caracteres.
     * @returns boolean - Retorna `true` se a raiz do CNPJ contém exatamente 8 caracteres, indicando um formato válido.
     *                    Caso contrário, retorna `false`, indicando que o formato não é válido.
     */
    protected calculateAndCompareDigits(raizCnpj: string): boolean {
        // Verifica se o comprimento da string fornecida é exatamente 8 caracteres.
        return raizCnpj.length === 8;
    }
}

/**
 * Validador para números de processos judiciais no formato CNJ (Conselho Nacional de Justiça).
 * Valida a estrutura e os dígitos verificadores de um número de processo, utilizando
 * o algoritmo do módulo 97 para assegurar a validade do número fornecido.
 */
class CNJValidator extends BaseValidator {
    /**
     * Valida o número do processo judicial com base em seus dígitos de verificação.
     *
     * @param processo - String contendo o número do processo a ser validado.
     *                   Espera-se que tenha exatamente 20 caracteres, representando
     *                   tanto os dígitos do número do processo quanto os dígitos de verificação.
     * @returns boolean - Retorna `true` se o número do processo é válido conforme os critérios
     *                    especificados; caso contrário, retorna `false`.
     */
    protected calculateAndCompareDigits(processo: string): boolean {
        // Verifica se o tamanho do número do processo é válido
        if (processo.length !== 20) {
            return false;
        }

        // Define o tamanho das partes em que a base será dividida para o cálculo.
        const partSize = 7;

        // Prepara o valor para o cálculo do checksum, omitindo os dígitos de verificação e adicionando '00' no final
        const valueForCalculation = processo.substring(0, 7) + processo.substring(9) + '00';

        // Compara o checksum calculado com os dígitos de verificação do número do processo
        return processo.substring(7, 9) === this.checksumForMod97(valueForCalculation, partSize);
    }
}
