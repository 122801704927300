/**
 * A classe CurrencyUtil oferece métodos estáticos para a formatação de valores numéricos em formatos de moeda.
 * É uma ferramenta útil para aplicações que necessitam exibir valores monetários de forma legível e de acordo com as
 * convenções locais de moeda.
 */
export class CurrencyUtil {
    /**
     * Formata um valor numérico para uma representação em string de uma moeda especificada.
     *
     * @param {string | number} value O valor a ser formatado. Pode ser um número ou uma string numérica.
     * @param {string} currency O código ISO 4217 da moeda para a qual o valor deve ser formatado. Por padrão, é 'BRL'.
     * @returns {string} Uma string representando o valor formatado na moeda especificada, incluindo o símbolo da moeda.
     * @throws {Error} Lança um erro se o valor fornecido não puder ser convertido para um número.
     *
     * Nota: Este método utiliza a localização 'pt-BR' para a formatação. Ajustes para outras localizações podem ser feitos
     * modificando o parâmetro de localização passado para o método toLocaleString, se necessário.
     */
    static format(value: string | number, currency: string = 'BRL'): string {

        const numberValue = Number(value);
        if (isNaN(numberValue)) {
            throw new Error('Valor inválido para formatação de moeda.');
        }

        return numberValue.toLocaleString('pt-BR', {style: 'currency', currency: currency});
    }
}
