import {Constants, UUIDUtil, DateUtil} from './Utils'; // Ajuste os imports conforme necessário
import {IColumnHeader, ICommonData} from "../types/Interfaces";
import {IPdfPage, PDFGenerator} from '../helpers/PDFGenerator';

/**
 * `PdfExportUtil` é um utilitário projetado para facilitar a exportação de dados para arquivos PDF.
 * Este objeto encapsula funcionalidades para gerar documentos PDF a partir de um conjunto de dados e
 * definições de coluna, aplicando um layout específico e incluindo informações contextuais,
 * como detalhes do usuário logado. Ele utiliza o `PDFGenerator` para criar os documentos,
 * garantindo que a geração do PDF seja flexível e configurável conforme necessário.
 */
export const PdfExportUtil = {
    /**
     * Exporta os dados fornecidos para um arquivo PDF, utilizando colunas base e outras informações.
     * Gera um nome de arquivo único com um UUID e utiliza um layout pré-definido para a página PDF.
     *
     * @param data Array de dados comuns a serem incluídos no PDF.
     * @param baseColumns Colunas base que definem os cabeçalhos a serem usados no PDF.
     * @param loggedIn Informações adicionais sobre o usuário logado ou outro contexto relevante.
     */
    async exportToFile(data: ICommonData[], baseColumns: IColumnHeader[], loggedIn: any) {
        const filename: string = `documento-${UUIDUtil.generate()}.pdf`;

        const page: IPdfPage = {
            unit: 'pt', // Unidade de medida usada nas dimensões da página.
            format: 'a4', // Formato da página.
            layout: {
                orientation: 'landscape', // Orientação da página.
                border: {
                    header: 40, // Espaçamento do cabeçalho.
                    footer: 20, // Espaçamento do rodapé.
                }
            },
            dimensions: {
                height: 595.28, // Altura da página.
                width: 841.89, // Largura da página.
            },
        };

        if (data) { // Verifica se há dados para exportar.
            const headers = baseColumns; // Define os cabeçalhos a partir das colunas base.
            //pdf({ title: Constants.TITLE_REPORT, data, headers, filename, other: loggedIn });
            const pdfGenerator = new PDFGenerator(page); // Instancia o gerador de PDF com a configuração da página.

            // Gera o PDF com o título, subtítulo, dados, cabeçalhos e outras informações fornecidas.
            pdfGenerator.generate({
                title: Constants.TITLE_REPORT,
                subtitle: `${Constants.DEBIT_REFERENCE}: ${DateUtil.getReference()}`,
                data, headers, filename,
                other: loggedIn
            });
        }
    }
}