import jsPDF, {jsPDFOptions} from "jspdf";
import logo from "../../assets/img/logo.png";

interface HeaderData {
    title: string;
    subtitle: string;
    pathImage: string;
}

interface FooterData {
    centerText: string;
}

interface WatermarkData {
    text: string;
}

interface TableHeader {
    label: string;
    key: string;
    xPos?: number;
}

export interface IPageLayout {
    orientation?: "landscape" | "p" | "portrait" | "l";
    border: {
        header: number;
        footer: number;
    };
}

export interface IPdfPage {
    unit?: "pt" | "px" | "in" | "mm" | "cm" | "ex" | "em" | "pc";
    format: string;
    layout: IPageLayout;
    dimensions: {
        height: number;
        width: number;
    };
}

export interface IBody {
    margins: {
        top: number,
        left: number,
        right: number,
        bottom: number
    },
    width: number,
    height: number
}

export interface PdfData {
    title: string;
    subtitle: string;
    data: any[];
    headers: TableHeader[];
    filename: string;
    other: {
        cpf: string;
        name: string;
    };
}

export class PDFGenerator {
    private document: jsPDF;
    private page: IPdfPage;
    private body: IBody;

    constructor(page: IPdfPage) {
        this.page = page;
        this.document = new jsPDF({
            orientation: page.layout.orientation,
            unit: page.unit,
            format: page.format,
        } as jsPDFOptions);
        this.body = {
            margins: {
                top: page.layout.border.header + 20,
                left: 20,
                right: 20,
                bottom: page.layout.border.footer + 10
            },
            width: page.dimensions.width,
            height: page.dimensions.height - page.layout.border.header - page.layout.border.footer
        }
    }

    private getPageSize() {
        return this.document.internal.pageSize;
    }

    private getBorder() {
        return this.page.layout.border;
    }

    private getMargins() {
        return this.body.margins;
    }

    private resetTextStyles() {
        this.document
            .setTextColor(0, 0, 0)
            .setFontSize(8);
    }

    private addWatermark({text}: WatermarkData) {
        const {width, height} = this.getPageSize();
        const offset = 230;
        const xCenter = width / 2 - offset;
        const yCenter = height / 2 + offset;
        const options = {angle: 45, charSpace: 10};

        this.document
            .setTextColor(150)
            .setFontSize(70)
            .text(text, xCenter, yCenter, options);

        this.resetTextStyles();
    }

    private addHeader({title, subtitle, pathImage}: Partial<HeaderData>) {
        const {width} = this.getPageSize();
        const {header} = this.getBorder();
        const totalPages = this.document.getNumberOfPages();

        for (let i = 0; i < totalPages; i++) {
            this.document
                .setPage(i + 1)
                .setFillColor(0, 4, 68)
                .rect(0, 0, width, header, 'F');

            if (pathImage) {
                // Consider using a method to load and add images asynchronously if possible
                this.document
                    .addImage(pathImage, 'png', 10, 5, 100, 30);
            }

            // Title
            if (title) {
                this.document
                    .setTextColor(255)
                    .setFontSize(14)
                    .text(title, (width - this.document.getTextWidth(title)) / 2, 25);
            }

            // Subtitle
            if (subtitle) {
                this.document
                    .setFontSize(9)
                    .text(subtitle, (width - this.document.getTextWidth(subtitle) - 10), 25);
            }
        }

        this.resetTextStyles();
    }

    private addFooter({centerText}: FooterData) {
        const totalPages = this.document.getNumberOfPages();
        const {width, height} = this.getPageSize();
        const {footer} = this.getBorder();

        for (let i = 0; i < totalPages; i++) {
            this.document
                .setPage(i + 1)
                .setFillColor(0, 4, 68)
                .rect(0, height - footer, width, footer, 'F');

            const currentPage = i + 1;
            const yPos = height - footer / 2;
            const dateTime = new Date().toLocaleString('pt-BR', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
                hour: "numeric",
                minute: "numeric",
            });

            this.document
                .setTextColor(255)
                .text(`Pagina ${currentPage} de ${totalPages}`, 10, yPos)
                .text(centerText, (width - this.document.getTextWidth(centerText)) / 2, yPos)
                .text(dateTime, (width - this.document.getTextWidth(dateTime) - 10), yPos);
        }

        this.resetTextStyles();
    }

    private addHeadersTable(headers: TableHeader[]) {
        const {top, left, right} = this.getMargins(); // Supondo que existam margens definidas em this.body
        const {width} = this.getPageSize();
        const usableWidth = width - left - right;
        const cellWidth = usableWidth / headers.length;

        let xPosition = left;

        headers.forEach(({label, xPos}, index) => {
            let x = xPos ?? xPosition;
            this.document.text(label, x, top);

            // Se não houver xPos definido, incrementar xPosition para o próximo cabeçalho
            if (!xPos) {
                xPosition += cellWidth;
            }
        });

        // Desenha uma linha abaixo dos cabeçalhos, ajuste a posição Y conforme necessário
        this.document.line(left, top + 5, width - right, top + 5);
    }

    private addTable(headers: TableHeader[], data: any[], watermarkCallback: () => void) {
        const {top, left, right, bottom} = this.getMargins(); // Supondo que existam margens definidas em this.body
        const {width, height} = this.getPageSize();
        const usableWidth = width - left - right;
        const cellWidth = usableWidth / headers.length;

        // Adiciona os cabeçalhos da tabela
        watermarkCallback?.();
        this.addHeadersTable(headers);

        let currentY = top + 10; // Ajuste conforme necessário para o espaço abaixo do cabeçalho

        // ROWS
        data.forEach((row, rowIndex) => {
            let maxHeight = 0;

            headers.forEach((header, colIndex) => {
                const cellContent = row[header.key].toString();
                const splitText = this.document.splitTextToSize(cellContent, cellWidth - 5); // Subtrai um pouco para o padding
                const cellHeight = splitText.length * this.document.getLineHeight(); // Ajuste este valor conforme necessário

                if (cellHeight > maxHeight) {
                    maxHeight = cellHeight;
                }

                // Checa se precisa de uma nova página
                if (currentY + maxHeight > height - bottom - this.document.getLineHeight()) {
                    this.document.addPage();
                    currentY = top; // Reset Y position on the new page
                    // Opcional: Repetir cabeçalhos da tabela na nova página
                    watermarkCallback?.();
                    this.addHeadersTable(headers);
                    currentY += 10; // Ajuste conforme o espaço abaixo do cabeçalho
                }

                const x = left + cellWidth * colIndex;
                this.document.text(splitText, x + 2, currentY + 5); // Adiciona um pequeno padding
            });

            currentY += maxHeight;
        });
    }

    public generate({title, subtitle, data, headers, filename, other}: PdfData) {
        this.addTable(headers, data, () => this.addWatermark({text: other.cpf}));

        this.addHeader({title, subtitle, pathImage: logo});
        this.addFooter({centerText: `Emitido por ${other.name}, CPF: ${other.cpf}`});

        this.document.save(filename);
    }
}