/**
 * Estende URLSearchParams para suportar a adição de arrays com diferentes formatos.
 */
class ExtendedURLSearchParams extends URLSearchParams {
    /**
     * Adiciona um array de valores para uma chave específica, com suporte a múltiplos formatos.
     *
     * @param key A chave sob a qual os valores serão adicionados.
     * @param values Os valores a serem adicionados. Podem ser strings ou números.
     * @param format O formato de como os valores do array serão adicionados.
     *               - 'csv': Todos os valores como uma única string separada por vírgulas.
     *               - 'brackets': Cada valor com colchetes após o nome da chave.
     *               - 'index': Cada valor com um índice na chave.
     *               - 'default': Cada valor é adicionado separadamente sob a mesma chave.
     */
    appendArray(key: string, values: (string | number)[], format: 'default' | 'csv' | 'brackets' | 'index' = 'default') {
        switch (format) {
            case 'csv':
                this.append(key, values.join(','));
                break;
            case 'brackets':
                values.forEach(value => this.append(`${key}[]`, value.toString()));
                break;
            case 'index':
                values.forEach((value, index) => this.append(`${key}[${index}]`, value.toString()));
                break;
            default:
                values.forEach(value => this.append(key, value.toString()));
        }
    }
}

type TArrayFormat = 'csv' | 'brackets' | 'index' | 'default';

type TQueryParams = string | string[][] | Record<string, string> | URLSearchParams | undefined;

export class QueryParamsBuilder {
    static build(params: TQueryParams, arrayFormat: TArrayFormat = 'default') {
        const searchParams = new ExtendedURLSearchParams();

        if (params === undefined) return '';

        Object.entries(params).forEach(([key, value]) => {
            if (value === undefined) return;

            Array.isArray(value)
                ? searchParams.appendArray(key, value, arrayFormat)
                : searchParams.append(key, value.toString());
        });

        return searchParams.toString();
    }
}

interface IUrlOptions {
    path: string | number;
    queryParams: TQueryParams;
    fragment: string | number;
    arrayFormat: TArrayFormat;
}

/**
 * Fornece uma interface fluente para a construção de URLs.
 */
export class URLBuilder {
    /**
     * Constrói uma URL a partir de uma base e opções especificadas.
     *
     * @param baseUrl A base da URL, que pode ser uma string ou uma instância de URL.
     * @param options Um objeto opcional contendo as partes da URL a serem modificadas ou adicionadas.
     *                - `path`: Um caminho adicional para a URL.
     *                - `queryParams`: Um objeto contendo os parâmetros de consulta.
     *                - `fragment`: Um fragmento (âncora) para ser adicionado à URL.
     *                - `arrayFormat`: O formato de como os arrays nos parâmetros de consulta devem ser tratados.
     * @returns A URL construída como uma string.
     */
    static build(baseUrl: string | URL, options?: Partial<IUrlOptions>): string {
        const url = new URL(baseUrl);

        if (options?.queryParams) {
            url.search = QueryParamsBuilder.build(options.queryParams, options.arrayFormat);
        }

        if (options?.path) {
            const path = options.path.toString().replace(/^\/|\/$/g, '');
            url.pathname += url.pathname.endsWith('/') ? path : `/${path}`;
        }

        if (options?.fragment) {
            const fragment = options.fragment.toString().replace(/^\/|\/$/g, '');
            url.hash += url.hash.endsWith('/') ? fragment : `/${fragment}`;
        }

        return new URL(url).toString();
    }
}