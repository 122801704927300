import React, {useEffect, useRef, useState} from "react";
import ShowDialog from "./lib/Dialog";

const SessionTimeout = ({timeInMinutes, action}: { timeInMinutes: number; action: () => void; }) => {
    const [timeoutModalOpen, setTimeoutModalOpen] = useState<boolean>(false);
    const [timeoutCountdown, setTimeoutCountdown] = useState<number>(0);

    const timeoutRef = useRef<number>(0);
    const countdownIntervalRef = useRef<number>(0);

    const handleLogout = (isTimedOut: boolean = false) => {
        setTimeoutModalOpen(isTimedOut);
        clearSessionTimeout();
        action?.();
    };

    const clearSessionTimeout = () => {
        clearInterval(countdownIntervalRef.current);
        clearTimeout(timeoutRef.current);
        localStorage.removeItem('sessionEndTime');
    }

    useEffect(() => {
        const endTimeKey = 'sessionEndTime';

        const calculateRemainingTime = () => {
            const savedTime = localStorage.getItem(endTimeKey);
            if (savedTime) {
                const remainingTime = parseInt(savedTime) - new Date().getTime();
                return remainingTime > 0 ? remainingTime : null;
            }
            return null;
        };

        const setupTimers = (remainingTime: number) => {
            timeoutRef.current = window.setTimeout(() => {
                let countDown = 10;
                setTimeoutModalOpen(true);
                setTimeoutCountdown(countDown);
                countdownIntervalRef.current = window.setInterval(() => {
                    if (countDown > 0) {
                        setTimeoutCountdown(--countDown);
                    } else {
                        handleLogout(true);
                    }
                }, 1000);
            }, remainingTime);
        };

        const onIdle = (): void => {
            const remainingTime = calculateRemainingTime();
            if (remainingTime !== null) {
                setupTimers(remainingTime);
            } else {
                const delay = 1000 * 60 * timeInMinutes;
                const endTime = new Date().getTime() + delay;
                localStorage.setItem('sessionEndTime', endTime.toString());
                setupTimers(delay);
            }
        };

        onIdle();

        return () => clearSessionTimeout();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ShowDialog
            header="Tempo de Sessão Expirando."
            visible={timeoutModalOpen}
            message={`Sua sessão terminará em ${timeoutCountdown} segundos e em seguida redirecionado a tela de login. Caso queria continuar, realize um novo acesso.`}
            onAction={() => handleLogout(false)}
        />
    );
}

export default SessionTimeout;