import React, {useCallback} from 'react';
import { Button } from 'primereact/button';
import { usePopup } from '../hooks/Hooks';
import { DateUtil } from "../utils/Utils";
import User from './User';
import logo from '../../assets/img/logo.png';

const Navbar = ({ onLogout }: { onLogout: () => void }) => {

    const popup = usePopup();

    const handleLogout = useCallback( (): void => {
        popup.show("Sessão Encerrada", "Sessão encerrada com sucesso.", onLogout);
    },[popup, onLogout]);

    return (
        <header className="App-header flex flex-wrap align-items-center justify-content-between text-white">
                    <div className="flex align-items-center justify-content-center p-3 col-12 lg:col-3">
                        <img className="App-logo" alt="Logo da PGE/MT" src={logo} />
                    </div>
                    <div className="header-text-container p-3 col-12 lg:col-7 gap-2">
                        <h1 className="text-4xl">PORTAL DA TRANSPARÊNCIA</h1>
                        <h2 className="text-xl">Dívida Ativa Estadual e Requisição de Pequeno Valor</h2>
                        <p className="text-sm">Referência:&nbsp;{DateUtil.getReference()}</p>
                    </div>
                    <div className="flex align-items-center justify-content-end p-3 col-12 lg:col-2">
                        <div className="text-right">
                            <User className="user-info mr-2 text-xs" style={{ flexShrink: 0 }} />
                        </div>
                        <div>
                            <Button icon="pi pi-power-off" onClick={handleLogout} tooltip="Sair" className="p-button-rounded p-button-secondary" style={{ flexShrink: 0 }}/>
                        </div>
                    </div>
        </header>
    );
}

export default Navbar;