import React from 'react';
import { Environment } from '../../../shared-api/helpers/Environment';
import "./EnvBanner.css";

const EnvironmentBanner = () => {
    const env = Environment.get('REACT_APP_STAGE', 'local');

    let backgroundColor = '';
    let text = '';

    switch (env) {
        case 'local':        
        case 'dev':
            backgroundColor = 'red'; // Escolha uma cor para o ambiente de desenvolvimento.
            text = 'Desenvolvimento';
            break;

        case 'hmg':
            backgroundColor = 'orange'; // Escolha uma cor para o ambiente de homologação.
            text = 'Homologação';
            break;

        default:
            // Nenhuma faixa para outros ambientes.
            return null;
    }

    return (
        <div className="md:text-base environment-banner" style={{ backgroundColor, color: 'white', textAlign: 'center', padding: '1px' }}>
            {text.toUpperCase()}
        </div>
    );
};

export default EnvironmentBanner;
