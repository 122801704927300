import {TDocumentType, TQuery} from "../types/types";
import { MaskUtil } from "./MaskUtil";
import {ValidateUtil} from "./ValidateUtil";

interface ICategory {
    readonly key: TDocumentType;
    readonly label: string;
    readonly length: number;
    readonly mask: string;
    applyMask: (value: string) => string;
    validate: (value: string) => boolean;
    useIn: TQuery[];
}

/**
 * Utilitário para manipulação e validação de categorias de dados.
 */
export class CategoryUtil {
    private static readonly categories: ICategory[] = [
        {
            key: 'CPF',
            label: 'CPF',
            length: 14,
            mask: "999.999.999-99",
            applyMask: MaskUtil.cpf,
            validate: ValidateUtil.cpf,
            useIn: ['CDA', 'RPV']
        },
        {
            key: 'CNPJ',
            label: 'CNPJ',
            length: 18,
            mask: "99.999.999/9999-99",
            applyMask: MaskUtil.cnpj,
            validate: ValidateUtil.cnpj,
            useIn: ['CDA', 'RPV']
        },
        {
            key: 'RADICAL_CNPJ',
            label: 'CNPJ Base',
            length: 10,
            mask: "99.999.999",
            applyMask: MaskUtil.cnpjBase,
            validate: ValidateUtil.cnpjBase,
            useIn: ['CDA', 'RPV']
        },
        {
            key: 'PROCESSO',
            label: 'Processo',
            length: 25,
            mask: "9999999-99.9999.9.99.9999",
            applyMask: MaskUtil.processo,
            validate: ValidateUtil.cnj,
            useIn: ['RPV']
        }
    ];

    /**
     * Retorna todas as categorias disponíveis.
     */
    static values() {
        return CategoryUtil.categories;
    }

    /**
     * Retorna uma categoria específica baseada no índice fornecido.
     *
     * @param index Índice da categoria desejada.
     * @returns A categoria correspondente ao índice.
     */
    static value(index: number = 0): ICategory {
        if (index < 0 || index >= CategoryUtil.categories.length) index = 0;
        return CategoryUtil.categories[index];
    }
}