import React from 'react';
import ErrorPage from "../../components/ErrorPage";


const ServiceUnavailable: React.FC = () => {
    return (
        <ErrorPage
            icon="pi pi-exclamation-triangle"
            color="#ffc107"
            title="Serviço Indisponível Temporariamente"
            message="Desculpe, o serviço está temporariamente indisponível. Tente novamente mais tarde."
            buttonText="Ir para Página da PGE/MT"
            buttonIcon="pi pi-chevron-left"
            actionRedirect={() => window.location.replace("https://www.pge.mt.gov.br/d%C3%8Dvida-ativa")}
        />
    );
};

export default ServiceUnavailable;