// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* TermContent.css */
.term-title {
    font-size: 1.2em; /* Tamanho similar a um h1 */
    font-weight: bold; /* Equivalente a <strong> */
    padding-bottom: 20px; /* Espaçamento conforme o exemplo original */
    text-align: center; /* Se desejar centralizar o texto */
}

.term-p {
    margin-bottom: 0.8em; /* Ajusta o espaçamento abaixo do parágrafo */
    font-weight: normal; /* Garante que o texto tenha o peso desejado */
    text-indent: 2em; /* Indenta a primeira linha para destacá-la visualmente */
}`, "",{"version":3,"sources":["webpack://./src/app/components/TermContent/TermContent.css"],"names":[],"mappings":"AAAA,oBAAoB;AACpB;IACI,gBAAgB,EAAE,4BAA4B;IAC9C,iBAAiB,EAAE,2BAA2B;IAC9C,oBAAoB,EAAE,4CAA4C;IAClE,kBAAkB,EAAE,mCAAmC;AAC3D;;AAEA;IACI,oBAAoB,EAAE,6CAA6C;IACnE,mBAAmB,EAAE,8CAA8C;IACnE,gBAAgB,EAAE,yDAAyD;AAC/E","sourcesContent":["/* TermContent.css */\n.term-title {\n    font-size: 1.2em; /* Tamanho similar a um h1 */\n    font-weight: bold; /* Equivalente a <strong> */\n    padding-bottom: 20px; /* Espaçamento conforme o exemplo original */\n    text-align: center; /* Se desejar centralizar o texto */\n}\n\n.term-p {\n    margin-bottom: 0.8em; /* Ajusta o espaçamento abaixo do parágrafo */\n    font-weight: normal; /* Garante que o texto tenha o peso desejado */\n    text-indent: 2em; /* Indenta a primeira linha para destacá-la visualmente */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
