// hooks/useLoggedIn.ts

import { useEffect } from 'react';
import { TokenController } from "../../mtlogin-api/controllers/TokenController";
import { useAuth } from './useAuth';
import { UserController } from '../../mtlogin-api/controllers/MTLoginUserController';

export const useInitializeSession = () => {

    const { login, logout } = useAuth();
    const code = new URLSearchParams(window.location.search).get('code');

    useEffect(() => {
        if (code) {
            const sessionController = new TokenController(code);
            sessionController.hasToken().then(hasToken => {
                if (hasToken) {
                    const userController = new UserController();
                    userController.getUserInformation().then(session => {
                        if (session.loggedIn) {
                            login(session.loggedIn, session.registered);
                        } else {
                            logout();
                        }
                    });
                } else {
                    logout();
                }
            })
        }
    }, [code, login, logout]);

};