// Endpoints.ts

import {Environment} from "../shared-api/helpers/Environment";
import {URLBuilder} from "../shared-api/helpers/URLBuilder";

export class Endpoints {
    private static readonly ADDRESS_API = Environment.get("REACT_APP_HOST_LOGIN");
    private static readonly MTLOGIN_CONTEXT = "auth/realms/mt-realm/protocol/openid-connect";

    private static readonly BASE_API = URLBuilder.build(Endpoints.ADDRESS_API, {path: Endpoints.MTLOGIN_CONTEXT});

    static readonly TOKEN = URLBuilder.build(Endpoints.BASE_API, {path: "token"});
    static readonly USER = URLBuilder.build(Endpoints.BASE_API, {path: "userinfo"});

    private static readonly HOST_ASSETS = Environment.get("REACT_APP_HOST_ASSETS");
    private static readonly APP_CONTEXT = Environment.get("REACT_APP_CONTEXT");

    private static readonly BASE_APP = URLBuilder.build(Endpoints.HOST_ASSETS, {path: Endpoints.APP_CONTEXT});

    static readonly REDIRECT_LOGIN = URLBuilder.build(Endpoints.BASE_APP, {path: 'home'});
    static readonly REDIRECT_LOGOUT = URLBuilder.build(Endpoints.BASE_APP, {path: 'signin'});

    private static getCommonParams() {
        return {
            client_id: Environment.get("REACT_APP_CLIENT_ID"),
            response_type: 'code',
        };
    }

    static readonly LOGIN = URLBuilder.build(Endpoints.BASE_API, {
        path: "auth", queryParams: {
            ...Endpoints.getCommonParams(),
            redirect_uri: Endpoints.REDIRECT_LOGIN,
        }
    });

    static readonly LOGOUT = URLBuilder.build(Endpoints.BASE_API, {
        path: "logout", queryParams: {
            ...Endpoints.getCommonParams(),
            redirect_uri: Endpoints.REDIRECT_LOGOUT
        }
    });
}