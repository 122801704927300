import { IColumnDefinition, IColumnConfig } from "../types/Interfaces";
import { CurrencyUtil } from '../utils/CurrencyUtil';

export const ColumnUtil = {
    transform(columns: IColumnDefinition[], transformFn: (col: IColumnDefinition) => any) {
        return columns.map(transformFn);
    },
    createColumnsDefinition(config: IColumnConfig): IColumnDefinition[] {
        return [
          {key: config.numeroKey, header: config.numeroHeader},
          {key: "documento", header: "CPF/CNPJ", path: "contribuinte.documento.numero"},
          {key: "contribuinte", header: "Contribuinte", path: "contribuinte.nome"},
          {key: config.dataInscricaoKey, header: config.dataInscricaoHeader},
          {key: "situacaoAtualProcesso", header: "Situação Atual Processo"},
          {key: "tipoProcesso", header: "Tipo de Processo"},
          {key: "valorTotal", header: "Valor Total", format: (data) => CurrencyUtil.format(data)},
        ];
      }
}