export class ConnectionError extends Error {
    constructor(message: string = 'Erro de conexão: Não foi possível conectar ao servidor.') {
        super(message);
        this.name = "ConnectionError";
    }
}

export class SessionCodeMissingError extends Error {
    constructor(message: string = '[Erro][MTLogin]: Não foi fornecido o código da sessão.') {
        super(message);
        this.name = "SessionCodeMissingError";
    }
}

export class InvalidTokenError extends Error {
    constructor(message: string = 'O access_token é inválido ou ausente na resposta.') {
        super(message);
        this.name = "InvalidTokenError";
    }
}

export class FetchRequestError extends Error {
    private _status: number;

    constructor(status: number, text: string) {
        super(`Falha na solicitação com status ${status}: ${text}`);
        this._status = status;
        this.name = "FetchRequestError";
    }

    get status() {
        return this._status;
    }
}

export class NonJsonResponseError extends Error {
    private _status: number;

    constructor(status: number, message: string = 'O tipo de conteúdo da resposta não é JSON') {
        super(message);
        this.name = "NonJsonResponseError";
        this._status = status;
    }

    get status() {
        return this._status;
    }
}

export class SessionTokenMissingError extends Error {
    constructor(message: string = '[Erro][MTLogin]: Não foi fornecido o token da sessão.') {
        super(message);
        this.name = "SessionTokenMissingError";
    }
}

export class UnexpectedDataFormatError extends Error {
    constructor(message: string = 'Formato de dados inesperado.') {
        super(message);
        this.name = 'UnexpectedDataFormatError';
    }
}