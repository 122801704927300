/**
 * Tipagem para opções de formatação de data, baseada nas opções de Internacionalização do JavaScript.
 */
type DateFormatOptions = Intl.DateTimeFormatOptions;

export class DateUtil {
    /**
     * Obtém a data e hora atual.
     * @returns {Date} Uma instância de Date representando o momento atual.
     */
    static getCurrentDate(): Date {
        return new Date();
    }

    /**
     * Retorna o ano atual.
     * @returns {number} O ano atual como um número.
     */
    static getYearCurrent(): number {
        return DateUtil.getCurrentDate().getFullYear();
    }

    /**
     * Retorna a data e hora atuais formatadas de forma completa.
     * @returns {string} Data e hora atuais no formato "dia mês ano hora:minuto", localizado para pt-BR.
     */
    static getFullDate(): string {
        return DateUtil.getDateFormat(DateUtil.getCurrentDate(), {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
            hour: "numeric",
            minute: "numeric"
        });
    }

    /**
     * Retorna a referência do mês e ano atual.
     * @returns {string} Mês e ano atuais no formato numérico "mês/ano", localizado para pt-BR.
     */
    static getReference(): string {
        return DateUtil.getDateFormat(DateUtil.getCurrentDate(), {
            month: 'numeric',
            year: 'numeric'
        });
    }

    /**
     * Formata uma data de acordo com as opções fornecidas.
     * @param {Date} date A data a ser formatada.
     * @param {DateFormatOptions} [options] As opções de formatação de data.
     * @returns {string} A data formatada como uma string, localizada para pt-BR.
     */
    static getDateFormat(date: Date, options?: DateFormatOptions): string {
        return date.toLocaleString('pt-BR', options);
    }

    /**
     * Adiciona minutos a uma data específica.
     * @param {Date} date A data base para adição dos minutos.
     * @param {number} minutes O número de minutos a serem adicionados.
     * @returns {Date} A nova data, após a adição dos minutos.
     */
    static addMinutes(date: Date, minutes: number): Date {
        return new Date(date.getTime() + minutes * 60 * 1000);
    }
}