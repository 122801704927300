/* eslint-disable */
import React, { createContext } from 'react';

export type ToastContextType = {
    showSuccess: (title: string, message: string) => void;
    showInfo: (title: string, message: string) => void;
    showError: (title: string, message: string) => void;
}

// create context
const ToastContext = createContext<ToastContextType | undefined>(undefined)

export default ToastContext;
/* eslint-enable */