import React, {useEffect, useState} from "react";
import Loading from "./Loading";
import {useAuth} from "../hooks/useAuth";
import {Endpoints} from "../../mtlogin-api/Endpoints";
import {useNavigate} from "react-router-dom";

// Função para verificar a disponibilidade do serviço de autenticação
async function verificarDisponibilidadeServico(url: string): Promise<boolean> {
    try {
        const response = await fetch(url, {mode: 'no-cors', method: 'HEAD'});
        return response.type === 'opaque' && response.status === 0; // Retorna true se o status for 2xx
    } catch (error) {
        return false; // O serviço pode estar indisponível
    }
}

export const SignIn = () => {
    const {session} = useAuth();
    const [tentativas, setTentativas] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        if (!session.loggedIn) {
            if (tentativas < 3) { // Limite para o número de tentativas
                const delay = Math.pow(2, tentativas) * 1000; // 2^tentativas segundos
                const timer = setTimeout(() => {
                    verificarDisponibilidadeServico(Endpoints.LOGIN)
                        .then((disponivel) => {
                            if (disponivel) {
                                window.location.replace(Endpoints.LOGIN);
                            } else {
                                setTentativas(tentativas + 1);
                            }
                        })
                        .catch(() => {
                            setTentativas(tentativas + 1);
                        });
                }, delay);

                return () => clearTimeout(timer);
            } else {
                navigate('/serviceunavailable');
            }
        }
    }, [session.loggedIn, navigate, tentativas]); // Adicione "tentativas" às dependências

    return (
        <Loading/>
    );
};
