/**
 * Objeto `StringUtil`: Uma coleção de métodos utilitários para manipulação de strings,
 * especialmente projetado para operações comuns envolvendo strings que representam números,
 * como documentos fiscais e identificadores. Oferece funcionalidades para extrair partes específicas
 * de uma string, remover caracteres não numéricos e mais. Estas funções são amplamente utilizadas
 * em validações de documentos, onde a manipulação precisa de strings numéricas é crucial.
 */
export class StringUtil {
    /**
     * Extrai os primeiros N dígitos de uma string.
     * Este método é útil para casos onde é necessário trabalhar com uma parte específica de uma string numérica,
     * como extrair a raiz de um CNPJ ou os primeiros dígitos de um CPF.
     *
     * @param str A string da qual os dígitos iniciais serão extraídos.
     * @param numberDigits O número de dígitos a serem extraídos do início da string.
     * @returns Uma substring contendo os primeiros N dígitos especificados de `str`.
     */
    static firstDigits(str: string, numberDigits: number): string {
        return str.slice(0, numberDigits);
    }

    /**
     * Extrai os últimos N dígitos de uma string.
     * Utilizado em situações onde os dígitos finais de uma string são necessários,
     * como verificar os dígitos verificadores de documentos fiscais ou identificações.
     *
     * @param str A string da qual os dígitos finais serão extraídos.
     * @param numberDigits O número de dígitos a serem extraídos do final da string.
     * @returns Uma substring contendo os últimos N dígitos especificados de `str`.
     */
    static lastDigits(str: string, numberDigits: number): string {
        return str.slice(str.length-numberDigits, str.length);
    }

    /**
     * Remove todos os caracteres não numéricos de uma string. Essencial para a preparação
     * de strings numéricas antes de realizar validações ou outros processamentos, garantindo
     * que a string contenha apenas dígitos.
     *
     * @param value Valor a ser limpo. Pode ser `undefined`, caso em que uma string vazia é retornada.
     * @returns Uma string contendo apenas dígitos. Se `value` for `undefined`, retorna uma string vazia.
     */
    static extractDigits(value: string | undefined): string {
        if (!value) return '';

        return value.replace(/\D/g, ''); // Remove non-numeric characters
    }
}