import React from "react";
import Hyperlink from "./lib/Hyperlink";
import { Constants, DateUtil } from "../utils/Utils";
import {Environment} from "../../shared-api/helpers/Environment";
import packageJson from "../../../package.json"; // Importa o package.json para acessar a versão

const Footer: React.FC = React.memo(() => {
    const links = [
        {
            label: "Portal PGE/MT",
            href: Constants.HOME_PAGE
        },
        {
            label: "Perguntas Frequentes",
            href: Constants.COMMON_QUESTIONS
        }
    ];

    return (
        <footer className='App-footer flex-wrap text-white'>
            <div className="col-12 lg:col-2 hidden md:block">
                <div>
                    <span className="footer-link App-link-footer">v.{packageJson.version}-{Environment.get('REACT_APP_STAGE', '')}</span>
                </div>
            </div>
            <div className="footer-content col-12 lg:col-8">
                <div>
                    <span>{Constants.APP_NAME}</span>
                    <span> &copy; {DateUtil.getYearCurrent()} {Constants.STATE_BODY}.</span>
                </div>
                <div>
                    <span>{Constants.DEVELOPED_BY}.</span>
                </div>
            </div>
            <div className="footer-links col-12 lg:col-2">
                {links.map(link => {
                    return (
                        <div key={link.label}>
                            <Hyperlink label={link.label} href={link.href} className="footer-link App-link-footer"/>
                        </div>
                    )
                })}
            </div>
        </footer>
    );
})

export default Footer;