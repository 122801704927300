import React from "react";

export interface IHyperlinkProps {
    label: string,
    href: string,
    className?: string,
    style?: React.CSSProperties
}

const Hyperlink: React.FC<IHyperlinkProps> = React.memo(({className, href, label, style}: IHyperlinkProps) => {
    return (
        <a href={href} target="_blank" rel="noopener noreferrer" className={className ?? 'App-link'} style={style}>
            {label}
        </a>
    );
});

export default Hyperlink;