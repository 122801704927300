// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* EnvBanner.css */

.environment-banner {
    top: 0;
    width: 100%;
    z-index: 1000;
}`, "",{"version":3,"sources":["webpack://./src/app/components/EnvBanner/EnvBanner.css"],"names":[],"mappings":"AAAA,kBAAkB;;AAElB;IACI,MAAM;IACN,WAAW;IACX,aAAa;AACjB","sourcesContent":["/* EnvBanner.css */\n\n.environment-banner {\n    top: 0;\n    width: 100%;\n    z-index: 1000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
