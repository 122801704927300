import {Column} from 'primereact/column';
import {DataTable} from 'primereact/datatable';
import {Paginator, PaginatorPageState} from 'primereact/paginator';
import React, {useState, memo, useCallback} from 'react';
import ExportGroup from './ExportGroup';
import {rowsPerPage} from '../../utils/Utils';
import {IColumnHeader, ICommonData} from "../../types/Interfaces";

interface IPaginatorPage {
    first: number,
    rows: number
}

interface IViewTableProps {
    data: ICommonData[];
    columns: IColumnHeader[];
    rows: number;
    loading: boolean;
    totalRecords: number;
    visible: boolean;
    export?: () => Promise<void> | void;
    emptyMessage: string;
    onPagination: (page: number) => void;
}

const ViewTable: React.FC<IViewTableProps> = memo((props: IViewTableProps) => {

    const [pagination, setPagination] = useState<IPaginatorPage>({
        first: 0,
        rows: props.rows
    });

    const onPageChange = useCallback((event: PaginatorPageState): void => {
        const page = (event.first / event.rows) + 1;
        setPagination(prev => ({
            ...prev,
            first: event.first,
            rows: event.rows
        }))
        props.onPagination(page);
    }, [props]);

    const header = (
        <ExportGroup
            label='Exportar para arquivo'
            tooltip='PDF'
            visible={props.totalRecords > 0}
            onAction={props.export}
        />
    );

    return (
        props.visible ?
            <div style={{padding: '10px 5px 20px'}}>
                <div className="card">
                    <DataTable
                        header={header}
                        value={props.data}
                        responsiveLayout="scroll"
                        loading={props.loading}
                        emptyMessage={props.emptyMessage}>
                        {props.columns.map(e =>
                            <Column
                                key={e.key}
                                field={e.key}
                                header={e.label}
                                //body={e.body}
                                sortable
                            />)}
                    </DataTable>
                    <Paginator
                        first={pagination.first}
                        rows={pagination.rows}
                        totalRecords={props.totalRecords}
                        rowsPerPageOptions={rowsPerPage}
                        onPageChange={onPageChange}>
                    </Paginator>
                </div>
            </div> :
            <></>
    );
});

export default ViewTable;