import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React, {useCallback, useMemo, useRef, useState} from "react";
import { PopupContext } from "../contexts/Contexts";
import { PopupContextType } from "../contexts/PopupContext";

export interface IPopup {
    header: string,
    message: string,
    onAction?: () => void
}

export const PopupProvider = ({ children }: { children: React.ReactNode }) => {
    const [visible, setVisible] = useState(false);

    const dialogRef = useRef<IPopup>({
        header: '',
        message: '',
        onAction: undefined
    });

    const handleHide = useCallback((): void => {
        setVisible(false);
        if (dialogRef.current?.onAction) {
            dialogRef.current.onAction();
        }
    },[]);

    const show = useCallback((header: string, message: string, onAction?: () => void) => {
        dialogRef.current = { header, message, onAction };
        setVisible(true);
    },[]);

    const footer = (
        <div>
            <Button
                label="Ok"
                icon="pi pi-check"
                onClick={handleHide}
                autoFocus
            />
        </div>
    );

    // Dados globais do context
    const popupData: PopupContextType = useMemo(
        // eslint-disable-next-line react-hooks/exhaustive-deps
        () => ({show}), []
    );

    return (
        <PopupContext.Provider value={popupData}>
            <Dialog
                header={dialogRef.current.header}
                footer={footer}
                visible={visible}
                onHide={handleHide}
                style={{ width: '40vw' }}
                breakpoints={{ '960px': '75vw', '640px': '100vw' }}>
                <p className="m-0 justify-content-center">{dialogRef.current.message}</p>
            </Dialog>
            {children}
        </PopupContext.Provider>
    );
}