import React, {useCallback, useEffect, useState} from "react";
import {Button} from 'primereact/button';
import {Checkbox, CheckboxChangeParams} from 'primereact/checkbox';
import './Notification.css';
import {TermContent} from "../../components/TermContent";
import {useAuth} from "../../hooks/useAuth";

interface NotificationProps {
    onHide: () => void;
}

const Notification: React.FC<NotificationProps> = ({ onHide }) => {
    const {session} = useAuth();
    const [checked, setChecked] = useState<boolean>(() => {
        return false;
    });

    const handleChange = useCallback( (event: CheckboxChangeParams): void => {
        event.preventDefault();
        setChecked(event.checked);
    },[]);

    const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
        event.preventDefault();

        if (session.loggedIn?.guid) {
            localStorage.setItem(session.loggedIn?.guid, 'true');
        }
        onHide?.();
    };

    useEffect(() => {
        if (session.loggedIn?.guid && localStorage.getItem(session.loggedIn?.guid) === 'true') {
            onHide?.();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <div className="notification">
            <div>
                <TermContent/>
            </div>

            <div style={{flex: 20, backgroundColor: "#000444", height: "3px"}}/>

            <div className="agreementSection">
                <div className="col-8">
                    <p className="continueText">
                        Se continuar navegando neste site, você assume concordar com nossas políticas.
                    </p>
                </div>
                <div className="col-4">
                    <div>
                        <Checkbox inputId="cbxAcordo" checked={checked} onChange={handleChange} />
                        <label htmlFor="cbxAcordo" className="agreementLabel">
                            <strong>Li e estou de acordo</strong>
                        </label>
                    </div>
                    <div>
                        <Button className="agreementButton" label="Concordo" icon="pi pi-check" onClick={handleClick} disabled={!checked} autoFocus/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Notification;