// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ErrorPage.css */

.error-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f9f9f9;
}

.error-card {
    max-width: 600px;
}

.error-icon {
    font-size: 5em;
    padding-bottom: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/app/components/ErrorPage/ErrorPage.css"],"names":[],"mappings":"AAAA,kBAAkB;;AAElB;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,oBAAoB;AACxB","sourcesContent":["/* ErrorPage.css */\n\n.error-page {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n    background-color: #f9f9f9;\n}\n\n.error-card {\n    max-width: 600px;\n}\n\n.error-icon {\n    font-size: 5em;\n    padding-bottom: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
