import {TokenUtil} from "../../app/utils/Utils";
import {HTTPMethod} from "../enums/HTTPMethod";
import {ConnectionError, FetchRequestError, NonJsonResponseError} from "../exceptions/CustomError";
import {IRequestConfig} from "../models/IRequestConfig";

/**
 * DefaultRequestConfig implementa a interface IRequestConfig, fornecendo uma configuração padrão.
 */
class DefaultRequestConfig implements IRequestConfig {
    getHeaders(): HeadersInit {
        return {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${TokenUtil.getToken()}`
        };
    }
}

/**
 * ApiRequestHandler fornece uma classe utilitária estática para realizar requisições HTTP
 * usando a Fetch API, com suporte integrado para métodos REST comuns, como GET, POST
 * e PUT. Simplifica a inclusão de cabeçalhos padrão e personalizados, incluindo autorização,
 * e oferece uma maneira estruturada de tratar respostas e erros.
 */
export class ApiRequestHandler {
    /**
     * Realiza uma requisição GET.
     * @param input A URL ou objeto RequestInfo para a requisição.
     * @param queryParams Parâmetros de consulta opcionais para serem anexados à URL.
     * @param config Parâmetro opcional que permite a customização dos cabeçalhos da requisição.
     * @returns Uma Promise que resolve para a resposta processada como JSON.
     */
    static async get(input: RequestInfo | URL, queryParams?: Record<string, string>, config?: IRequestConfig) {
        const url = new URL(input.toString());
        if (queryParams) {
            Object.keys(queryParams).forEach(key => url.searchParams.append(key, queryParams[key]));
        }
        return await this.fetch(HTTPMethod.GET, url, null, config);
    }

    /**
     * Realiza uma requisição POST.
     * @param input A URL ou objeto RequestInfo para a requisição.
     * @param init Corpo opcional da requisição.
     * @param headers Cabeçalhos personalizados opcionais para incluir na requisição.
     * @param config Parâmetro opcional que permite a customização dos cabeçalhos da requisição.
     * @returns Uma Promise que resolve para a resposta processada como JSON.
     */
    static async post(input: RequestInfo | URL, init?: BodyInit | null, config?: IRequestConfig) {
        return await this.fetch(HTTPMethod.POST, input, init, config);
    }

    /**
     * Realiza uma requisição PUT.
     * @param input A URL ou objeto RequestInfo para a requisição.
     * @param init Corpo opcional da requisição.
     * @param headers Cabeçalhos personalizados opcionais para incluir na requisição.
     * @param config Parâmetro opcional que permite a customização dos cabeçalhos da requisição.
     * @returns Uma Promise que resolve para a resposta processada como JSON.
     */
    static async put(input: RequestInfo | URL, init?: BodyInit | null, config?: IRequestConfig) {
        return await this.fetch(HTTPMethod.PUT, input, init, config);
    }

    /**
     * Uma utilidade de fetch genérica que é usada pelos métodos GET, POST e PUT para
     * realizar as requisições reais. Inclui cabeçalhos padrão para JSON e autorização.
     * @param method O método HTTP a ser usado.
     * @param input A URL ou objeto RequestInfo para a requisição.
     * @param body Corpo opcional da requisição para os métodos POST e PUT.
     * @param config Parâmetro opcional que permite a customização dos cabeçalhos da requisição.
     * @returns Uma Promise que resolve para a resposta processada como JSON.
     */
    static async fetch(method: HTTPMethod, input: RequestInfo | URL, body?: BodyInit | null, config?: IRequestConfig) {
        const headers = new Headers(config?.getHeaders() ?? new DefaultRequestConfig().getHeaders());

        const init: RequestInit = {method, headers, ...(body ? {body} : {})};

        try {
            const response = await fetch(input, init);

            if (!response.ok) {
                const errorBody = await response.text();
                //throw new Error(`Request failed with status ${response.status}: ${errorBody}`);
                throw new FetchRequestError(response.status, errorBody);
            }

            // Verificação adicional para garantir que a resposta é JSON antes de tentar parseá-la
            const contentType = response.headers.get('Content-Type');
            if (!contentType || !contentType.includes('application/json')) {
                //throw new Error('O tipo de conteúdo da resposta não é JSON');
                throw new NonJsonResponseError(response.status);
            }

            return await response.json(); // Poderia retornar response.json() para tratar a resposta como JSON. Avaliar no futuro.
        } catch (error: unknown) {
            // Tratamento de erros de rede
            if (error instanceof FetchRequestError && error.status === 401) {
                throw new Error('Não é possível atender à solicitação neste momento. Solicitamos que tente novamente posteriormente.');
            }
            if (error instanceof TypeError && error.message === 'Failed to fetch') {
                throw new ConnectionError();
            }
            throw error; // Repassar o erro pode ser útil para tratamento adicional pelo chamador
        }
    }
}