import {MaskUtil, TokenUtil} from "../../app/utils/Utils";
import {AuthService} from "../../fe-api/services/AuthService";
import {SessionTokenMissingError} from "../../shared-api/exceptions/CustomError";

export interface IUser {
    guid: string;
    login: string;
    name: string;
    email: string;
    cpf: string;
    mother: string;
    born: string;
}

export type TSession = {
    loggedIn: IUser | undefined;
    registered: boolean;
}

export class UserController {
    private authService: AuthService;
    private readonly dataToken: React.ReactNode;

    constructor() {
        this.dataToken = TokenUtil.getDecodeObject();

        if (!this.dataToken) {
            throw new SessionTokenMissingError();
        }

        this.authService = new AuthService();
    }

    private anyToIUser(user: any): IUser {
        // Desestruturação do objeto para melhor legibilidade
        const { sub: guid, given_name: login, email, name, preferred_username, nomeMae: mother, dataNascimento: born } = user;
        const cpf = MaskUtil.cpf(preferred_username);
        return { guid, login, email, name, cpf, mother, born };
    }

    async getUserInformation(): Promise<TSession> {
        try {
            return Promise.resolve({
                loggedIn: this.anyToIUser(this.dataToken),
                registered: await this.authService.fetch()
            });
        } catch {
            return Promise.resolve({loggedIn: undefined, registered: false});
        }
    }
}