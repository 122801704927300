// BaseService.ts

import {ApiRequestHandler} from "../../shared-api/handlers/ApiRequestHandler";
import {IData} from "../interfaces/IData";
import {IService} from "../interfaces/IService";
import {QueryParamsBuilder} from "../../shared-api/helpers/URLBuilder";
import {NonJsonResponseError, UnexpectedDataFormatError} from "../../shared-api/exceptions/CustomError";
import {StringUtil} from "../../app/utils/StringUtil";

interface IResponseData {
    sucesso: number;
    meta: {
        pagina: number;
        qtdRegistros: number;
        tamanhoPagina: number;
        totalRegistros: number;
    };
    dados: any[]; // Considere definir uma interface mais específica para 'dados' se possível.
}

export abstract class BaseService implements IService {
    private readonly endpoint: string;

    protected constructor(endpoint: string) {
        this.endpoint = endpoint;
    }

    private isIResponseData(data: any): data is IResponseData {
        return data && typeof data === 'object' && typeof data.sucesso === 'number' && Array.isArray(data.dados) && typeof data.meta === 'object';
    }

    protected async getDataResponse(init: string | string[][] | Record<string, string> | URLSearchParams | undefined): Promise<IResponseData> {
        const params = QueryParamsBuilder.build(init);
        return await ApiRequestHandler.get(`${this.endpoint}?${params}`);
    }

    protected mapToIData(data: IResponseData): IData {
        if (!this.isIResponseData(data)) {
            throw new UnexpectedDataFormatError();
        }

        return {
            success: data.sucesso === 1,
            result: {
                pageSize: data.meta.tamanhoPagina,
                totalRecords: data.meta.totalRegistros,
                data: data.dados
            }
        };
    }

    async getData(documento: string, tipo: string, page?: number): Promise<IData> {
        const params = {
            documento: StringUtil.extractDigits(documento),
            tipo,
            ...(page ? {pagina: page.toString()} : {export: 'true'})
        };

        try {
            const data = await this.getDataResponse(params);
            return this.mapToIData(data);
        } catch (error) {
            if (error instanceof NonJsonResponseError && [204, 400].includes(error.status)) {
                const data = {
                    sucesso: 1,
                    meta: {
                        pagina: 0,
                        qtdRegistros: 0,
                        tamanhoPagina: 0,
                        totalRegistros: 0
                    },
                    dados: []
                };
                return this.mapToIData(data);
            }
            throw error;
        }
    }
}