import React from 'react';
import ErrorPage from "../../components/ErrorPage";
import {useNavigate} from "react-router-dom";

const NotFoundPage: React.FC = () => {
    const navigate = useNavigate();
    return (
        <ErrorPage
            icon="pi pi-times-circle"
            color="#d9534f"
            title="Página Não Encontrada"
            message="Desculpe, a página que você está procurando não existe ou foi removida."
            buttonText="Ir para Login"
            buttonIcon="pi pi-home"
            actionRedirect={() => navigate("/")} // Use o path do seu app para a home ou login
        />
    );
};

export default NotFoundPage;