import {TokenUtil} from "../../app/utils/Utils";
import {Endpoints} from "../Endpoints";
import {LoginService} from "../services/LoginService";
import {InvalidTokenError, SessionCodeMissingError} from "../../shared-api/exceptions/CustomError";
import {Environment} from "../../shared-api/helpers/Environment";

export class TokenController {
    constructor(private code: string) {
        if (!code) {
            throw new SessionCodeMissingError();
        }
        this.code = code;
    }

    async hasToken(): Promise<boolean> {
        try {
            let accessToken = TokenUtil.getToken();
            if (!accessToken) {
                const sessionData = await LoginService.post(Endpoints.TOKEN, {
                    grant_type: 'authorization_code',
                    client_id: Environment.get("REACT_APP_CLIENT_ID"),
                    redirect_uri: Endpoints.REDIRECT_LOGIN,
                    code: this.code,
                });

                if (typeof sessionData.access_token !== 'string') {
                    throw new InvalidTokenError();
                }

                accessToken = sessionData.access_token.trim();
                TokenUtil.setToken(accessToken);
            }

            return !!accessToken;
        } catch (error) {
            //console.error('[Erro][MTLogin]: ', error);
            return false;
        }
    }
}