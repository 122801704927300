import {IController} from "../interfaces/IController";
import {IService} from "../interfaces/IService";
import {IColumnDefinition, IColumnHeader, ICommonData} from "../../app/types/Interfaces";
import {IDataController} from "../interfaces/IData";
import {ObjectUtil} from "../../app/utils/Utils";

export abstract class BaseController implements IController {
    private service: IService;

    protected constructor(service: IService) {
        // Injeção de dependência permite flexibilidade e testabilidade
        this.service = service;
    }

    private async fetch(registration: string, type: string, page?: number) {
        if (!registration || !type) {
            throw new Error("O número e tipo de documento são obrigatórios.");
        }
        return await this.service.getData(registration, type, page);
    }

    async getData(registration: string, type: string, page?: number): Promise<IDataController> {
        const response = await this.fetch(registration, type, page);
        const headers = this.createHeaders();

        return {
            ...response,
            result: {
                ...response.result,
                headers: this.createHeaders(),
                data: this.transformTabularData(headers, response.result.data)
            }
        };
    }

    private transformTabularData(headers: { key: string, label: string }[], data: unknown[]) {
        return data.map((d: unknown) => {
            const mappedData: ICommonData = {};

            headers.forEach(({key}) => {
                const columnDefinition = this.createColumnsDefinition().find(col => col.key === key);
                const value = ObjectUtil.getValueByPath(d, columnDefinition?.path ?? key);
                mappedData[key] = columnDefinition?.format ? columnDefinition.format(value) : value;
            });

            return mappedData;
        });
    }

    private createHeaders(): IColumnHeader[] {
        return this.createColumnsDefinition()
            .map(({key, header}) => ({key, label: header}));
    }

    protected abstract createColumnsDefinition(): IColumnDefinition[];
}