import React from "react";
import Loading from "./Loading";
import {useAuth} from "../hooks/useAuth";
import {Endpoints} from "../../mtlogin-api/Endpoints";

export const SignOut = () => {
    const {session, logout} = useAuth();

    React.useEffect((): void => {
        if (session.loggedIn) {
            logout();
            window.location.replace(Endpoints.LOGOUT);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Loading />
    )
}