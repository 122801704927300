import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React, {useCallback, useEffect, useState} from "react";

export interface IShowDialogProps {
    header: string,
    visible: boolean,
    message: string,
    onAction?: () => void
}

const ShowDialog = ({header, message, onAction, visible}: IShowDialogProps) => {
    const [visibleState, setVisibleState] = useState(false);

    const handleHide = useCallback( (): void => {
        setVisibleState(false);
        onAction?.();
    }, [onAction]);

    const handleShow = useCallback( () => {
        setVisibleState(true);
    },[]);

    const footer: JSX.Element = (
        <div>
            <Button
                label="Ok"
                icon="pi pi-check"
                onClick={handleHide}
                autoFocus
            />
        </div>
    );

    useEffect(() => {
        if (visible) {
            handleShow();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible]);

    return (
        <Dialog
            header={header}
            footer={footer}
            visible={visibleState}
            onHide={handleHide}
            style={{ width: '40vw' }}
            breakpoints={{ '960px': '75vw', '640px': '100vw' }}>
            <p className="m-0 justify-content-center">{message}</p>
        </Dialog>
    );
}

export default ShowDialog;