// RpvService.ts

import {Endpoints} from "../Endpoints";
import {BaseService} from "./BaseService";
import {IService} from "../interfaces/IService";

export class RpvService extends BaseService implements IService {
    constructor() {
        super(Endpoints.RPVS);
    }

}