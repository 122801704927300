import {Button} from 'primereact/button';
import React, {useEffect, useState} from "react";

interface IInputSearchProps {
    value: string;
    name?: string;
    htmlFor?: string;
    maxLength: number;
    placeholder?: string;
    size?: number;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

const InputSearch = ({maxLength, size, name, htmlFor, onChange, placeholder, value}: IInputSearchProps) => {
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        setDisabled(maxLength !== value.length);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
        <div className="formgroup-inline">
            <div className="field">
                <input
                    type="text"
                    id={htmlFor ? htmlFor : "txtDocumento"}
                    name={name ? name : "txtDocumento"}
                    size={size ? size : 20}
                    value={value}
                    maxLength={maxLength}
                    placeholder={placeholder}
                    onChange={onChange}
                    className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                />
            </div>
            <div>
                <Button
                    label="Consultar"
                    icon="pi pi-search"
                    className="p-button-warning"
                    disabled={disabled}
                />
            </div>
        </div>
    )
}

export default InputSearch;