/**
 * `ObjectUtil` é uma coleção de utilitários para operações comuns em objetos JavaScript.
 * Ele fornece métodos que simplificam a manipulação e consulta de estruturas de objetos.
 */
export const ObjectUtil = {
    /**
     * Recupera um valor de um objeto baseado em uma string de caminho.
     * Este método permite acessar valores aninhados dentro de um objeto de forma segura,
     * mesmo se algum nível intermediário for `undefined` ou `null`.
     *
     * O caminho é especificado como uma string, onde cada segmento representa um nível no objeto.
     * Segmentos são separados por pontos. Por exemplo, "usuario.endereco.cidade" acessaria
     * `obj['usuario']['endereco']['cidade']`.
     *
     * @param obj O objeto do qual o valor será extraído.
     * @param path A string de caminho que especifica o valor a ser acessado, separado por pontos.
     * @returns O valor encontrado no caminho especificado, ou `undefined` se o caminho não existir.
     *
     * Exemplo de uso:
     * ```typescript
     * const usuario = {
     *   nome: 'João',
     *   endereco: {
     *     cidade: 'São Paulo',
     *     bairro: 'Centro'
     *   }
     * };
     * const cidade = ObjectUtil.getValueByPath(usuario, 'endereco.cidade'); // Retorna 'São Paulo'
     * ```
     */
    getValueByPath(obj: any, path: string): any {
        return path.split('.').reduce((acc, part) => acc?.[part], obj);
    }
}