import React, { useCallback, useState } from 'react';
import { PopupProvider } from '../providers/PopupProvider';
import { ToastProvider } from '../providers/ToastProvider';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import Notification from './Notification';
import SessionTimeout from '../components/SessionTimeout';
import { useNavigate } from "react-router-dom";
import ViewData from "../components/ViewData";
import { TabPanel, TabView } from "primereact/tabview";
import { ColumnUtil } from '../utils/ColumnUtil';
import { IColumnDefinition } from '../types/Interfaces';
import { TQuery } from "../types/types";
import EnvironmentBanner from '../components/EnvBanner';
import { Environment } from "../../shared-api/helpers/Environment";
import { useAuth } from "../hooks/useAuth";
import Loading from '../components/Loading';

const Home: React.FC = () => {

    const { session } = useAuth();
    const [show, setShow] = useState<boolean>(() => {
        if (session.loggedIn?.guid) {
            return localStorage.getItem(session.loggedIn?.guid) === 'true';
        }
        return false;
    });
    const navigate = useNavigate();

    const timeout = Number(Environment.get('REACT_APP_TIMEOUT_IN_MINUTES', '10'));

    const handleHide = useCallback((): void => {
        setShow(true);
    }, []);

    const handleLogout = useCallback(async () => {
        navigate('/signout');
    }, [navigate]);

    return (
        !session.loggedIn ?
            <Loading /> :
            <>
                <SessionTimeout timeInMinutes={timeout} action={handleLogout} />
                <EnvironmentBanner />

                {show ?
                    <Content onLogout={handleLogout} /> :
                    <Notification onHide={handleHide} />}

                <Footer />
            </>
    );
}

interface IDataForm {
    label: string;
    key: TQuery;
    component: {
        title: string;
        label: string;
        columns: IColumnDefinition[];
    };
}

const Content = ({ onLogout }: { onLogout: () => void }) => {

    const dataForms: IDataForm[] = [
        {
            label: 'Dívida Ativa Estadual',
            key: 'CDA',
            component: {
                title: "Consultar Dívida Ativa",
                label: "Na seção a seguir, podem ser consultados todos os débitos registrados como dívida ativa estadual.",
                columns: ColumnUtil.createColumnsDefinition({
                    numeroKey: "numeroCDA",
                    numeroHeader: "Número CDA",
                    dataInscricaoKey: "dataInscricaoCDA",
                    dataInscricaoHeader: "Data Inscrição CDA",
                })
            }
        },
        {
            label: 'Requisição de Pequeno Valor',
            key: 'RPV',
            component: {
                title: "Consultar Requisição de Pequeno Valor",
                label: "Na seção abaixo, podem ser consultados todos os pagamentos registrados como requisições de pequeno valor.",
                columns: ColumnUtil.createColumnsDefinition({
                    numeroKey: "numeroRPV",
                    numeroHeader: "Processo RPV",
                    dataInscricaoKey: "dataInscricaoRPV",
                    dataInscricaoHeader: "Data Inscrição RPV",
                })
            }
        }
    ];

    return (
        <PopupProvider>
            <ToastProvider>
                <Navbar onLogout={onLogout} />
                <TabView>
                    {dataForms.map(dataForm =>
                        <TabPanel header={dataForm.label} key={dataForm.key} leftIcon="pi pi-search mr-2">
                            <ViewData
                                title={dataForm.component.title}
                                category={dataForm.key}
                                label={dataForm.component.label}
                                columns={dataForm.component.columns}
                            />
                        </TabPanel>
                    )}
                </TabView>
            </ToastProvider>
        </PopupProvider>
    );
}

export default Home;