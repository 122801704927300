import React from 'react';
import { StringUtil } from "../utils/Utils";
import { useAuth } from "../hooks/Hooks";

const User = React.memo(({ className, style }: { className?: string, style?: React.CSSProperties }) => {

    const { session } = useAuth();

    const firstDigits = StringUtil.firstDigits(StringUtil.extractDigits(session.loggedIn?.cpf), 9);

    return (
        <div className={className} style={style}>
            <span>Usuário: {session.loggedIn?.login}</span>
            <br />
            <span>@{firstDigits}</span>
        </div>
    );
})

export default User;