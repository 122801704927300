/* eslint-disable */
import React, { useContext } from "react";
import { ToastContext } from "../contexts/Contexts";

export const useToast = () => {
    const context = useContext(ToastContext);

    if (!context) {
        throw new Error("useToast have to be used within ToastProvider");
    }

    return context;
}
/* eslint-enable */