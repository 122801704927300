/* eslint-disable */
import React, { createContext } from "react";

export type PopupContextType = {
    show: (title: string, message: string, action?: () => void) => void;
}

// create context
const PopupContext = createContext<PopupContextType | undefined>(undefined);

export default PopupContext;
/* eslint-enable */