import {RpvService} from "../services/RpvService";
import {IService} from "../interfaces/IService";
import {BaseController} from "./BaseController";
import { IColumnDefinition } from "../../app/types/Interfaces";
import {CurrencyUtil} from "../../app/utils/Utils";

export class RpvController extends BaseController {
    constructor(service?: IService) {
        super(service ?? new RpvService());
    }

    protected createColumnsDefinition(): IColumnDefinition[] {
        return [
            {key: "processoNumero", header: "Processo RPV", path: "processo.numero"},
            {key: "credorDocumentoNumero", header: "CPF/CNPJ", path: "credor.documento.numero"},
            {key: "credorNome", header: "Credor", path: "credor.nome"},
            {key: "dataInscricao", header: "Data Inscrição RPV"},
            {key: "processoSituacao", header: "Situação Atual Processo", path: "processo.situacao"},
            {key: "processoTipo", header: "Tipo de Processo", path: "processo.tipo"},
            {key: "valorTotal", header: "Valor Total", format: (data) => CurrencyUtil.format(data)},
        ];
    }
}