// providers/AuthProvider.tsx

import React, { useState } from "react";
import { IUser, TSession } from "../../mtlogin-api/controllers/MTLoginUserController";
import { AuthContext } from "../contexts/Contexts";

// wrap context provider to add functionality
export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    const [session, setSession] = useState<TSession>({ loggedIn: undefined, registered: false });

    const login = (user: IUser, registered: boolean) => {
        if (!session.loggedIn) {
            setSession({loggedIn: user, registered});
        }
    };

    const logout = () => {
        if (session.loggedIn) {
            sessionStorage.clear();
            setSession({ loggedIn: undefined, registered: false });
        }
    };

    return <AuthContext.Provider value={{ session, login, logout }}>{children}</AuthContext.Provider>;
};