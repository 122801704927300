import React from 'react';
import {Constants} from "../../utils/Utils";
import Hyperlink from "../lib/Hyperlink";
import "./TermContent.css";

export const TermContent: React.FC = React.memo(() => {
    return (
        <>
            <div className="term-title">
                TERMO DE USO DO PORTAL DA TRANSPARÊNCIA DA DÍVIDA ATIVA ESTADUAL
            </div>
            <div>
                <p className="term-p">
                    O presente <b>Termo de Uso</b> foi elaborado em conformidade com a&nbsp;
                    <Hyperlink
                        label={"Lei Federal nº 12.965, de 23 de abril de 2014"}
                        href={Constants.MARCO_CIVIL_INTERNET}
                    /> (Marco Civil da Internet), e com a&nbsp;
                    <Hyperlink
                        label={"Lei Federal nº 13.709, de 14 de agosto de 2018"}
                        href={Constants.LGPD}
                    /> (Lei Geral de Proteção de Dados Pessoais).
                </p>

                <p className="term-p">
                    A PGE/MT disponibiliza a <b>consulta dos créditos inscritos em Dívida ativa estadual</b>, e se
                    compromete a cumprir as normas previstas na Lei Geral de Proteção de Dados Pessoais (LGPD), e
                    respeitar os princípios nela dispostos.
                </p>

                <p className="term-p">
                    Salienta-se que os débitos inscritos em dívida ativa <b>não</b> estão cobertos por sigilo, conforme
                    disposto no art. 198, §3º, II, do&nbsp;
                    <Hyperlink
                        label={"Código Tributário Nacional"}
                        href={Constants.CODIGO_TRIBUTARIO_NACIONAL}
                    />.
                </p>

                <p className="term-p">
                    A consulta abrange os créditos inscritos em Dívida ativa estadual, indicando sua respectiva
                    situação, e seu resultado pode ser exportado para arquivo no formato PDF. Os parâmetros de entrada
                    para a realização das buscas são CPF, CNPJ ou CNPJ Base, a fim de facilitar pesquisa do usuário.
                </p>

                <p className="term-p">
                    Os números dos CPFs dos devedores pessoa física foram <b>parcialmente mascarados</b>, preservando-se
                    este
                    dado pessoal nos termos da Lei Geral de Proteção de Dados (
                    <Hyperlink
                        label={"Lei Federal nº 13.709/2018"}
                        href={Constants.LGPD}
                    />).
                </p>

                <p className="term-p">
                    Considerando as tecnologias envolvidas no processo ou a existência de intercorrências, informamos
                    que poderá demorar até <b>5 dias úteis</b> para ocorrer a baixa do débito no sistema de
                    acompanhamento de
                    dívidas.
                </p>

                <p className="term-p">
                    Salienta-se que o valor do crédito considera o ano e mês vigente como data de atualização do mesmo.
                </p>

                <p className="term-p">
                    A pesquisa retorna os débitos que se encontram nas seguintes situações processuais (<b>inscrito</b>,
                    <b>pré-ajuizado</b>, <b>em pagamento</b>, <b>em garantia de juízo</b>, <b>suspenso</b> e
                    <b>protestado</b>).
                </p>

                <p className="term-p">
                    Caso seja encontrada alguma inconsistência, favor entrar em contato com a Procuradoria Geral de
                    Mato Grosso através da&nbsp;
                    <Hyperlink
                        label={"Ouvidoria"}
                        href={Constants.OUVIDORIA}
                    /> pelo link:&nbsp;
                    <Hyperlink
                        label={Constants.OUVIDORIA}
                        href={Constants.OUVIDORIA}
                    />.
                </p>

                <p className="term-p">
                    Os dados retornados nas consultas não substituem e nem prejudicam os efeitos das informações
                    constantes nas certidões de regularidade fiscal emitidas pela PGE/MT.
                </p>

                <p className="term-p">
                    Ao utilizar os serviços, você confirma que leu, compreendeu o Termo de Uso aplicável ao serviço
                    solicitado e concorda em ficar a ele vinculado.
                </p>
            </div>
        </>
    );
});