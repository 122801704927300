import { Toast, ToastSeverityType } from 'primereact/toast';
import React, {useCallback, useMemo, useRef} from 'react';
import { ToastContext } from "../contexts/Contexts";
import { ToastContextType } from '../contexts/ToastContext';

// wrap context provider to add functionality
export const ToastProvider = ({ children }: { children: React.ReactNode }) => {

    // Hook useToast
    const toastRef = useRef<Toast | null>(null);

    const showToast = useCallback((type: ToastSeverityType, title: string, detail: string, life?: number) => {
        if (!toastRef.current) return;
        toastRef.current?.show({severity: type, summary: title, detail: detail, life: life});
    },[]);

    const showSuccess = (title: string, message: string) => {
        showToast('success', title, message, 3000);
    }

    const showInfo = (title: string, message: string) => {
        showToast('info', title, message, 3000);
    }

    const showError = (title: string, message: string) => {
        showToast('error', title, message, 3000);
    }

    // Dados globais do context
    const toastData: ToastContextType = useMemo(
        // eslint-disable-next-line react-hooks/exhaustive-deps
        () => ({showSuccess, showInfo, showError}), []
    );

    return (
        <ToastContext.Provider value={toastData}>
            <Toast ref={toastRef} />
            {children}
        </ToastContext.Provider>
    );
}