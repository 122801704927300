import React from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import './Loading.css'; // Importando estilos CSS externos

const Loading: React.FC = React.memo(() => {
    return (
        <div className="loading-container">
            <div className="loading-content">
                <ProgressSpinner strokeWidth="5" style={{ width: '50px', height: '50px' }} />
                <div className="loading-text">AGUARDE...</div>
            </div>
        </div>
    );
})

export default Loading;
