// ErrorPage.tsx
import React from 'react';
import {Button} from 'primereact/button';
import {Card} from 'primereact/card';
import 'primeicons/primeicons.css';
import './ErrorPage.css'; // Estilos customizados

interface ErrorPageProps {
    icon: string;
    color: string;
    title: string;
    message: string;
    buttonText: string;
    buttonIcon: string;
    actionRedirect: () => void;
}

const ErrorPage: React.FC<ErrorPageProps> = React.memo(({
                                                            icon,
                                                            color,
                                                            title,
                                                            message,
                                                            buttonText,
                                                            buttonIcon,
                                                            actionRedirect
                                                        }) => {
    return (
        <div className="error-page">
            <Card className="text-center error-card">
                <i className={`${icon} error-icon`} style={{color}}></i>
                <h1>{title}</h1>
                <p>{message}</p>
                <Button label={buttonText} icon={buttonIcon} className="p-button-text" onClick={actionRedirect}/>
            </Card>
        </div>
    );
});

export default ErrorPage;