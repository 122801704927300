// AuthService.ts

import {Endpoints} from "../Endpoints";
import {ApiRequestHandler} from "../../shared-api/handlers/ApiRequestHandler";

export class AuthService {

    async fetch(): Promise<boolean> {
        try {
            const response = await ApiRequestHandler.post(Endpoints.AUTHENTICATIONS);
            return response.ok;
        } catch (error: unknown) {
            //console.error("AuthService fetch error:", error);
            return false; // Ou throw error; se preferir propagar o erro.
        }
    }
}