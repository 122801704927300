import {Environment} from "../shared-api/helpers/Environment";
import {URLBuilder} from "../shared-api/helpers/URLBuilder";

export class Endpoints {
    private static readonly ADDRESS_API = Environment.get("REACT_APP_HOST_ASSETS");
    private static readonly BASE_FE_CONTEXT = Environment.get("REACT_APP_BASE_FE_API");
    private static readonly BASE_API = URLBuilder.build(Endpoints.ADDRESS_API, {path: Endpoints.BASE_FE_CONTEXT});

    static readonly CDAS = URLBuilder.build(Endpoints.BASE_API, { path: "cdas"});
    static readonly RPVS = URLBuilder.build(Endpoints.BASE_API, { path: "rpvs"});
    static readonly AUTHENTICATIONS = URLBuilder.build(Endpoints.BASE_API, { path: "autenticacoes"});
}