import {TDocumentType} from "../types/types";
import {StringUtil} from "./Utils";

/**
 * `MaskUtil` fornece métodos estáticos para aplicar máscaras formatadoras a diferentes tipos de documentos,
 * como CPF, CNPJ, base do CNPJ, e números de processo. Cada método utiliza a `MaskFactory` para obter
 * a instância da máscara apropriada e aplica a formatação ao valor fornecido.
 */
export const MaskUtil = {
    /**
     * Aplica a máscara de formatação a um número de CPF fornecido.
     * O método transforma uma string de dígitos numéricos em um formato de CPF padrão brasileiro (###.###.###-##).
     *
     * @param value Uma string contendo os dígitos de um CPF sem formatação.
     * @returns Uma string formatada como CPF. Se o valor fornecido não corresponder ao número esperado de dígitos para um CPF, a função ainda tentará formatar o valor conforme possível.
     */
    cpf(value: string): string {
        return MaskFactory.getMask('CPF').format(value);
    },

    /**
     * Formata um número de CNPJ fornecido segundo o padrão de formatação brasileiro (##.###.###/####-##).
     * Este método é utilizado para inserir os caracteres de formatação (pontos, barra e hífen) nos locais apropriados de um CNPJ.
     *
     * @param value Uma string contendo os dígitos de um CNPJ sem formatação.
     * @returns Uma string formatada como CNPJ. Caso o número de dígitos não corresponda exatamente ao de um CNPJ completo, a função tentará aplicar a máscara da melhor forma possível.
     */
    cnpj(value: string): string {
        return MaskFactory.getMask('CNPJ').format(value);
    },

    /**
     * Aplica uma máscara de formatação à raiz de um CNPJ (os primeiros 8 dígitos), conforme o padrão brasileiro (##.###.###).
     * Essa funcionalidade é útil para situações em que apenas a base do CNPJ é necessária, sem os dígitos de filial e verificadores.
     *
     * @param value Uma string contendo os primeiros 8 dígitos de um CNPJ sem formatação.
     * @returns Uma string formatada representando a base do CNPJ. Se o valor fornecido não tiver 8 dígitos, a máscara será aplicada conforme possível.
     */
    cnpjBase(value: string): string {
        return MaskFactory.getMask('RADICAL_CNPJ').format(value);
    },

    /**
     * Formata um número de processo judicial conforme o padrão de numeração unificado (NNNNNNN-DD.AAAA.J.TR.OOOO).
     * Este método insere os pontos, hífen e demais caracteres de separação conforme a estrutura do número de processo.
     *
     * @param value Uma string contendo os dígitos de um número de processo sem formatação.
     * @returns Uma string formatada como um número de processo. Se o número de dígitos não for adequado para a formatação completa, a função tentará aplicar a máscara da melhor forma possível.
     */
    processo(value: string): string {
        return MaskFactory.getMask('PROCESSO').format(value);
    }
}

/**
 * `MaskFactory` é uma classe que implementa o padrão de projeto factory para criar instâncias de máscaras
 * específicas de documentos. Com base no tipo de documento fornecido, retorna a máscara correspondente
 * que sabe como formatar aquele tipo específico de valor.
 */
class MaskFactory {
    /**
     * Cria e retorna uma instância de máscara específica com base no tipo de documento.
     * @param type O tipo de documento para o qual uma máscara é solicitada.
     * @returns Uma instância de `BaseMask` especializada no tipo de documento fornecido.
     * @throws Error Se o tipo de documento fornecido não for reconhecido.
     */
    static getMask(type: TDocumentType): BaseMask {
        // Implementação da lógica de seleção de máscara.
        switch (type) {
            case 'CPF':
                return new CPFMask();
            case 'CNPJ':
                return new CNPJMask();
            case 'RADICAL_CNPJ':
                return new CNPJBaseNumberMask();
            case 'PROCESSO':
                return new CNJMask();
            default:
                throw new Error("Mask type not recognized.");
        }
    }
}

/**
 * Classe abstrata que serve como base para todas as máscaras de documento. Define a estrutura básica
 * e o contrato que todas as máscaras devem seguir, incluindo um método para aplicar a máscara ao valor fornecido.
 */
abstract class BaseMask {
    /**
     * Formata um valor fornecido aplicando a máscara de documento específica.
     * @param value O valor a ser formatado.
     * @returns O valor formatado de acordo com a máscara específica.
     */
    public format(value: string): string {
        // Implementação de preparação e aplicação da máscara.
        const cleanedValue = StringUtil.extractDigits(value);
        return this.apply(cleanedValue);
    }

    /**
     * Método abstrato para aplicar a máscara específica ao valor fornecido. Deve ser implementado pelas subclasses.
     * @param value O valor limpo (apenas dígitos) a ser formatado.
     * @returns O valor formatado.
     */
    protected abstract apply(value: string): string;
}

/**
 * Aplica a máscara de CPF ao valor fornecido.
 */
class CPFMask extends BaseMask {
    /**
     * Implementação de `apply` para CPF.
     * @param value Valor limpo a ser formatado.
     * @returns Valor formatado como CPF.
     */
    apply(value: string): string {
        return value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4');
    }
}

/**
 * Aplica a máscara de CNPJ ao valor fornecido.
 */
class CNPJMask extends BaseMask {
    /**
     * Implementação de `apply` para CNPJ.
     * @param value Valor limpo a ser formatado.
     * @returns Valor formatado como CNPJ.
     */
    apply(value: string): string {
        return value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');
    }
}

/**
 * Aplica a máscara à base do CNPJ (primeiros 8 dígitos) ao valor fornecido.
 */
class CNPJBaseNumberMask extends BaseMask {
    /**
     * Implementação de `apply` para a raiz do CNPJ.
     * @param value Valor limpo a ser formatado.
     * @returns Valor formatado como Raiz do CNPJ.
     */
    apply(value: string): string {
        return value.replace(/^(\d{2})(\d{3})(\d{3})$/, '$1.$2.$3');
    }
}

/**
 * Aplica a máscara de número de processo judicial ao valor fornecido.
 */
class CNJMask extends BaseMask {
    /**
     * Implementação de `apply` para número de processo.
     * @param value Valor limpo a ser formatado.
     * @returns Valor formatado como Número Único.
     */
    apply(value: string): string {
        return value.replace(/^(\d{7})(\d{2})(\d{4})(\d)(\d{2})(\d{4})$/, '$1-$2.$3.$4.$5.$6');
    }
}