/* eslint-disable */
import {CdaController} from "../../fe-api/controllers/CdaController";
import {useToast} from "./useToast";
import {IController} from "../../fe-api/interfaces/IController";
import {TQuery} from "../types/types";
import {RpvController} from "../../fe-api/controllers/RpvController";

export const useController = (useIn: TQuery) => {
    const toast = useToast();

    const controllerMap = new Map<TQuery, IController>();
    controllerMap.set('CDA', new CdaController());
    controllerMap.set('RPV', new RpvController());

    const query = async (data: { registration: string, type: string, page?: number }) => {
        try {
            return controllerMap.get(useIn)?.getData(data.registration, data.type, data.page)
        } catch (error) {
            toast.showError('Falha de Comunicação', String(error));
        }
    }

    return {query};
}
/* eslint-enable */