import {Fieldset} from 'primereact/fieldset';
import {Panel} from 'primereact/panel';
import {RadioButton, RadioButtonChangeParams} from 'primereact/radiobutton';
import React, {ChangeEvent, FormEvent, useEffect, useRef, useState} from "react";
import {useAuth, useController, useToast} from '../../hooks/Hooks';
import {CategoryUtil, PdfExportUtil} from '../../utils/Utils';
import InputSearch from './InputSearch';
import {IColumnDefinition, IColumnHeader, ICommonData} from "../../types/Interfaces";
import ViewTable from "./ViewTable";
import {TQuery} from "../../types/types";

interface IDataSearchProps {
    title: string;
    label: string;
    category: TQuery;
    columns: IColumnDefinition[];
}

const ViewData = (props: IDataSearchProps) => {

    // useState Hooks
    const [documento, setDocumento] = useState("");
    const [loading, setLoading] = useState(false);
    const [commonData, setCommonData] = useState<ICommonData[]>([]);
    const [radioSelected, setRadioSelected] = useState(CategoryUtil.value(0));
    const [visible, setVisible] = useState(false);

    const rows: number = 20;

    // Custom Hooks
    const {session} = useAuth();
    const {showError} = useToast();
    const controller = useController(props.category);

    // useRef Hooks 
    const totalRecords = useRef<number>(0);
    const previousValue = useRef<string>("");
    const columnHeader = useRef<IColumnHeader[]>([]);

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!radioSelected.validate(documento)) {
            showError('Documento Inválido', `O ${radioSelected.label} informado é inválido.`);
            setVisible(false);
            return;
        }

        await handlePagination(1);
    }

    const handlePagination = async (page: number) => {
        try {
            setLoading(true);

            if (!visible) {
                setVisible(true)
            }

            await fetchDataByPage(previousValue.current, radioSelected.key, page);
        } catch (error) {
            //console.error(error);
			// nâo faz nada
        } finally {
            setLoading(false);
        }
    }

    const fetchDataByPage = async (registration: string, type: string, page: number) => {
        try {
            const response = await controller.query({registration, type, page});
            const records: number = response?.result.totalRecords ?? 0;
            const headers = response?.result.headers ?? [];

            if (totalRecords.current !== records) {
                totalRecords.current = records;
            }

            if (columnHeader.current !== headers) {
                columnHeader.current = headers
            }

            setCommonData(response?.success ? response.result.data : []);
        } catch (error) {
            setVisible(false);
            showError('Falha de Comunicação', String(error));
        } finally {
            if (documento !== "") {
                setDocumento("");
            }
        }
    }

    const fetchData = async (registration: string, type: string) => {
        const response = await controller.query({registration, type});

        if (response?.success) {
            return {headers: response?.result.headers, data: response?.result.data};
        }
    }

    const handleRadioChange = (event: RadioButtonChangeParams) => {
        const value = event.value;
        if (radioSelected.key !== value.key) {
            setRadioSelected(value);
            if (visible) setVisible(!visible);
            previousValue.current = "";
            setDocumento("");
        }
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setDocumento(radioSelected.applyMask(event.target.value));
        if (visible) {
            setVisible(false);
        }
    }

    const exportToFile = async () => {
        const response = await fetchData(previousValue.current, radioSelected.key)

        if (response?.data) {
            await PdfExportUtil.exportToFile(response.data, response.headers, session.loggedIn);
        }
    }

    useEffect(() => {
        if (documento) {
            previousValue.current = documento;
        }
    }, [documento]);

    return (
        <div className="App-body">
            <Panel header={props.title} className="mb" style={{padding: "10px 5px 50px"}}>
                <p style={{marginBottom: "20px"}}>{props.label}</p>
                <Fieldset legend="Selecione o tipo de documento desejado:">
                    <div className="formgrid grid">
                        <form onSubmit={handleSubmit}>
                            <div className="formgroup-inline">
                                {CategoryUtil.values().filter(f => f.useIn.includes(props.category)).map(category =>
                                    <div key={category.key} className="field-radiobutton">
                                        <RadioButton
                                            inputId={props.category.toLowerCase() + category.key}
                                            name={`'rdo'${props.category}`}
                                            value={category}
                                            onChange={handleRadioChange}
                                            checked={category.key === radioSelected.key}
                                        />
                                        <label htmlFor={props.category.toLowerCase() + category.key}>{category.label}</label>
                                    </div>)}
                            </div>

                            <div>
                                <InputSearch
                                    htmlFor={"txtDocumento" + props.category}
                                    name={"txtDocumento" + props.category}
                                    value={documento}
                                    maxLength={radioSelected.length}
                                    placeholder={`Digite o número do ${radioSelected.label}`}
                                    onChange={handleChange}
                                />
                            </div>
                        </form>
                    </div>
                </Fieldset>&nbsp;

                <ViewTable
                    data={commonData}
                    columns={columnHeader.current}
                    rows={rows}
                    loading={loading}
                    totalRecords={totalRecords.current}
                    visible={visible}
                    export={exportToFile}
                    emptyMessage={`Nenhum registro encontrado para o ${radioSelected.label} ${radioSelected.applyMask(previousValue.current)}`}
                    onPagination={handlePagination}
                />
            </Panel>
        </div>
    );
}

export default ViewData;